import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { CarrProvider } from '../../context/carruselContext';
import AgregarLibro from './components/agregLib';
import GridEdit from './gridEdit/gridEdit';
import EditarCarrusel from './components/editCarrusel';
import CopyDB from './components/copyDB';
import './Admin.css';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../../aws-exports';

Amplify.configure(awsExports);

const Admin = (signOut) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [toggle, setToggle] = useState("agr");

      function updateToggle (id) {
        setToggle(id);
      }

    const getAuthUser = async () => {
        // Note that session will no longer contain refreshToken and clockDrift
      try {
        const { tokens: session } = await fetchAuthSession();
        if (!session) throw new Error("SignInSession is empty!"); else {
        const groups = session.accessToken.payload["cognito:groups"] || [];
        setIsAdmin(groups.includes("Admin")); }
      } catch (err) {
        console.log (err);
      }
    }

    return (
      <>
        <section className="admin">
          <div className='containeradm'>
            <div className='admin-text'>
              <h2 className='admin-title fs-26 ls-1'>Administración</h2>
            </div>
            <Authenticator>
            {({ user, signOut }) => { if (user) {getAuthUser()}
                    return (
   //           {() => (
              <section>
            {isAdmin && (
              <>
        <div className="mi-cuenta">
        <h2 className="hola-salir">Hola {user.name ? (user.name) : (user.username)} | <button onClick={signOut} className="bton-salir"><Link to="/inicio" className="lnk-salir">Cerrar sesión</Link></button></h2>        
        </div>
              <div className= "uls">
              <ul>
                <li className={toggle === "agr" ? "bton-tab fs-20 yellow" : "bton-tab fs-20"} onClick={()=> updateToggle("agr")}> Agregar libro/revista nuevo</li>
                <li className={toggle === "edit" ? "bton-tab fs-20 yellow" : "bton-tab fs-20"}  onClick={()=> updateToggle("edit")}> Editar libro/revista</li>
                <li className={toggle === "editCarr" ? "bton-tab fs-20 yellow" : "bton-tab fs-20"}  onClick={()=> updateToggle("editCarr")}> Editar imágenes de carrusel</li>
              </ul>
              </div>
              <div className={toggle === "agr" ? "show" : "hide"}>
              <AgregarLibro />
              </div>
              <div className={toggle === "edit" ? "show" : "hide"}>
              <GridEdit />
              </div>
              <CarrProvider>
              <div className={toggle === "editCarr" ? "show" : "hide"}>
              <EditarCarrusel />
              </div>
              </CarrProvider>
              {isAdmin && user.username === "fer" && 
              <div className="show">
              <CopyDB />
              </div>}
              </>)}
             
            
             {!isAdmin && <div className="fw-6 fs-22 noAuth">
              <div className="mi-cuenta">
                <h2 className="hola-salir">Hola {user.name ? (user.name) : (user.username)} | <button onClick={signOut} className="bton-salir"><Link to="/inicio" className="lnk-salir">Cerrar sesión</Link></button></h2>        
              </div>
                <span>Usuario no autorizado</span>
              </div>}
           </section>);
          }}
         </Authenticator>
        </div>
       </section>
      </>
    )
}

export default withAuthenticator(Admin, { hideSignUp: true });
