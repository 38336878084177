import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loader";
import coverImg from "../../images/cover_not_found.jpg";
import "./PresentDetails.css";
import {FaArrowLeft} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import config from '../../aws-exports';
import { BookContext } from '../../context/books';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const PresentDetails = () => {
    const {idDet} = useParams();
    const [loading, setLoading] = useState(false);
    const [present, setPresent] = useState({});
    const [fotos, setFotos] = useState(null);
    const navigate = useNavigate();
    const { getEvento, idioma } = useContext(BookContext);

    useEffect(() => {
        setLoading(true);
        function formarFecha (dia, mes) {
            let mesEscrito = "";
            switch(idioma) {
                case "español":
                switch (mes) {
                    case 1: mesEscrito = "Enero"; break;
                    case 2: mesEscrito = "Febrero"; break;
                    case 3: mesEscrito = "Marzo"; break;
                    case 4: mesEscrito = "Abril"; break;
                    case 5: mesEscrito = "Mayo"; break;
                    case 6: mesEscrito = "Junio"; break;
                    case 7: mesEscrito = "Julio"; break;
                    case 8: mesEscrito = "Agosto"; break;
                    case 9: mesEscrito = "Septiembre"; break;
                    case 10: mesEscrito = "Octubre"; break;
                    case 11: mesEscrito = "Noviembre"; break;
                    case 12: mesEscrito = "Diciembre"; break;
                    default: break;
                }
                if (dia === 0) {return mesEscrito} 
                    else { return dia + " de " + mesEscrito}
                case "english":
                    switch (mes) {
                        case 1: mesEscrito = "January"; break;
                        case 2: mesEscrito = "February"; break;
                        case 3: mesEscrito = "March"; break;
                        case 4: mesEscrito = "April"; break;
                        case 5: mesEscrito = "May"; break;
                        case 6: mesEscrito = "June"; break;
                        case 7: mesEscrito = "July"; break;
                        case 8: mesEscrito = "August"; break;
                        case 9: mesEscrito = "September"; break;
                        case 10: mesEscrito = "October"; break;
                        case 11: mesEscrito = "November"; break;
                        case 12: mesEscrito = "December"; break;
                        default: break;
                    }
                    if (dia === 0) {return mesEscrito} 
                        else { return mesEscrito + " " + dia}
                default:
                break;
            }
        }

        async function getPresentDetails(){
            try{
                const data = await getEvento(idDet);
                if (data){
                    const {titulo, nomfoto, numfotos, lugar, autor, fotografo, resena, reseng, anoevento, mesevento, diaevento} = data;
                    const newPresent = {
                        titulo: titulo,
                        autor: autor,
                        fotografo: fotografo,
                        lugar: lugar,
                        nomfoto: nomfoto,
                        numfotos: numfotos,
                        diaymes: formarFecha(diaevento, mesevento),
                        anoevento: anoevento,
                        resena: resena ? resena : "No hay reseña",
                        reseng: reseng ? reseng : "No review",
                    };
                    const imgs = [];
                    for (let i=1; i <= data.numfotos; i++) {
                    let agregar = `${i}g.jpg`;
                    let buscar = ".jpg";
                    let gr1 = data.nomfoto.split(buscar);
                    let completo = `https://${bucket}.s3.${region}.amazonaws.com/fotos/${gr1[0]}` + agregar;
                    imgs.push({id: i, complet: completo});
                    }
                    setPresent(newPresent);
                    setFotos(imgs);
                } else {
                    setPresent(null);
                    setFotos(null);
                }
                setLoading(false);
            } catch(error){
                console.log(error);
                setLoading(false);
            }
        };
        getPresentDetails();
    }, [getEvento, idDet, setFotos, idioma]);

    if(loading) return <Loading />;

    switch (idioma) {
    case "español":
    return (
        <section className='present-details'>
            <div className="container">
                <button type='button' className="flex flex-c back-btn" onClick={() => { setPresent(null); setFotos(null); navigate("/present"); } }>
                    <FaArrowLeft size = {22} />
                    <span className="fs-18 fw-6">Atrás</span>
                </button>
                <div className='present-details-content grid'>
                    <div className='present-details-info'>
                        <div className='present-details-item title'>
                            <span className='fw-6 fs-24'>{present?.titulo}</span>
                        </div>
                        <div className='present-details-item'>
                            <span className='fw-6'>Autor: </span>
                           <span className='text-italic'>{present?.autor}</span>
                        </div>
                        <div className='present-details-item'>
                            <span className='fw-6'>Fecha de edición: </span>
                           <span className='text-italic'>{present?.diaymes + " de " + present?.anoevento}</span>
                        </div>
                        <div className='present-details-item'>
                            <span className='fw-6'>País de edición: </span>
                           <span className='text-italic'>{present?.lugar}</span>
                        </div>
                        <div className='present-details-item'>
                            <span className='fw-6'>Fotógrafo: </span>
                           <span className='text-italic'>{present?.fotografo}</span>
                        </div>
                        <div className='present-details-item description'>
                        <span className='fw-6 fs-12'>Reseña: </span>
                        <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: present?.resena }}></span>
                        </div>
                    </div>
                    <div className='present-details-img'>
                        {fotos ? fotos.map((item, index) => {
                            return ( <img key = {index} src = {item?.complet} alt = "cover img" /> )}) : <img src = {coverImg} alt = "cover img" />
                        }
                    </div>
                </div>
            </div>
        </section>
    )
    case "english":
        return (
            <section className='present-details'>
                <div className="container">
                    <button type='button' className="flex flex-c back-btn" onClick={() => { setPresent(null); setFotos(null); navigate("/present"); } }>
                        <FaArrowLeft size = {22} />
                        <span className="fs-18 fw-6">Back</span>
                    </button>
                    <div className='present-details-content grid'>
                        <div className='present-details-info'>
                            <div className='present-details-item title'>
                                <span className='fw-6 fs-24'>{present?.titulo}</span>
                            </div>
                            <div className='present-details-item'>
                                <span className='fw-6'>Author: </span>
                               <span className='text-italic'>{present?.autor}</span>
                            </div>
                            <div className='present-details-item'>
                                <span className='fw-6'>Event's date: </span>
                               <span className='text-italic'>{present?.diaymes + ", " + present?.anoevento}</span>
                            </div>
                            <div className='present-details-item'>
                                <span className='fw-6'>Place: </span>
                               <span className='text-italic'>{present?.lugar}</span>
                            </div>
                            <div className='present-details-item'>
                                <span className='fw-6'>Photographer: </span>
                               <span className='text-italic'>{present?.fotografo}</span>
                            </div>
                            <div className='present-details-item description'>
                            <span className='fw-6 fs-12'>Review: </span>
                            <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: present?.reseng }}></span>
                            </div>
                        </div>
                        <div className='present-details-img'>
                            {fotos ? fotos.map((item, index) => {
                                return ( <img key = {index} src = {item?.complet} alt = "cover img" /> )}) : <img src = {coverImg} alt = "cover img" />
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    default:
        break;
    }
}

export default PresentDetails;