import React, { useContext } from "react";
import { BookContext } from "../../context/books";
import Book from '../BookList/Book';
import Loading from "../Loader/Loader";
import "./BookList.css";


const BookList = () => {
    const {books, loading, resultTitle } = useContext(BookContext);

    if(loading) return <Loading />;

    books.forEach(item => {delete item.tituloMin; delete item.autorMin;});

    return (
        <section className='booklist'>
            <div className="container">
                <h2 className="result">{resultTitle}</h2>
            </div>
            <div className="booklist-content grid">
                {books.slice(0, 300).map((item, index) => {
                    return (
                        <Book key = {index} {... item} />
                    )
                })}
            </div>
        </section>
    )
}

export default BookList;