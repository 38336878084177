import React, { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { generateClient } from 'aws-amplify/api';
import { uploadData } from 'aws-amplify/storage';
import { downloadData } from 'aws-amplify/storage';
import { createLibros, createAutomov, createGuiasmx, createGuiasarg, createGuiasint, createRock, createTambien, createMasdep, createRevistas } from '../../../api/mutations';
import AlertMsg from './alertMsg';
import { validateLibro, validateGuia, validateOtros } from './validates';
//import config from '../../../aws-exports';
import "./components.css";

/*const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config*/

const client = generateClient();

const AgregarLibro = () => {
    const [alertm, setAlertm] = useState(false); //Controls Alert message
    const [alerte, setAlerte] = useState(false); //Controls Alert error
    const [msgPld, setMsgPld] = useState({ messg: "", title: "", severity: ""}) //Controls Message
    const [image, setImage] = useState(null);
    const [image2, setImage2] = useState(null);
    const [tabla, setTabla] = useState("Revistas");
    const [bookDetails, setBookDetails] = useState({ titulo: "", autor: "", nacautor: "", contautor: "", pais: "", editorial: "", tiraje: "", paginas: "", portada: "",  comentario: "", momento: "", diceautor: "" });
    const [guiaDetails, setGuiaDetails] = useState({ indice: "", titulo: "", institucion: "", nomfoto: "" });
    const [otrosDetails, setOtrosDetails] = useState({ titulo: "", categ: "", autor: "", diaedicion: "",  mesedicion: "", anoedicion: "", portada: "" });
    const [revistasDetails, setRevistasDetails] = useState({ titulo: "", autor: "", editorial: "", diaedicion: "",  mesedicion: "", anoedicion: "", portada: "" });
    const [errors, setErrors] = useState({});


    const showAlert = useCallback((opcion) => {
        setAlertm(true);
        if (!alerte) {
            switch (opcion) {
                case "agr":
                setMsgPld({messg: "El libro se agregó correctamente", title:"Libro nuevo agregado", severity: "success" });
                break;
                case "err":
                setMsgPld({messg: "Error en la base de datos de libros", title:"Error al agregar libro", severity: "error" });
                break;
                case "fal":
                    setMsgPld({messg: "Faltan agregar datos al formulario", title:"Aviso", severity: "warning" });
                break;
                default:
                break;
            }
        } else {
          setMsgPld({messg: "Error en la conexión a base de datos de libros", title:"Error al agregar libro", severity: "error" });
          };
      }, [alerte]);

      const enviarProd = async (errs) => {
        if (Object.keys(errs).length === 0 ){
        let query = {};
        let input;
        switch (tabla) {
            case "Revistas":
                query = createRevistas;
                input = { id: uuidv4, ...revistasDetails };
                break;
            case "Libros":
                query = createLibros;
                input = { id: uuidv4, ...bookDetails };
                break;
            case "Más deportes":  
                query = createMasdep;
                input = { id: uuidv4, ...bookDetails };
                break;
            case "Automovilismo":  
                query = createAutomov;
                input = { id: uuidv4, ...otrosDetails };
                break;
            case "Guías México":  
                query = createGuiasmx;
                input = { id: uuidv4, ...guiaDetails };
                break;                
            case "Guías Argentina":  
                query = createGuiasarg;
                input = { id: uuidv4, ...guiaDetails };
                break;                
            case "Guías Internacionales":  
                query = createGuiasint;
                input = { id: uuidv4, ...guiaDetails };
                break;                
            case "Rock":  
                query = createRock;
                input = { id: uuidv4, ...otrosDetails };
                break;                
            case "Y también...":  
                query = createTambien;
                input = { id: uuidv4, ...otrosDetails };
                break;                
            default:
              break;
            }
            try {
                console.log(input);
                await client.graphql({query: query, variables: { input: input }})
                setBookDetails({ titulo: "", tituloMin: "", autor: "", autorMin: "", pais: "", nacautor: "", contautor: "", editorial: "", tiraje: "", paginas: "", portada: "",  comentario: "", momento: "", diceautor: "", diaedicion: "", mesedicion: "", anoedicion: "" })
                setGuiaDetails({ indice: "", titulo: "", institucion: "", nomfoto: "" });
                setOtrosDetails({ titulo: "", categ: "", autor: "", diaedicion: "",  mesedicion: "", anoedicion: "", portada: "" });
                setRevistasDetails({ titulo: "", autor: "", editorial: "",  diaedicion: "",  mesedicion: "", anoedicion: "", portada: "" });
                showAlert("agr");
              } catch (err) {
                console.log('error creating todo:', err);
                setAlerte(true);
                showAlert("err");
              }
        setTimeout(() => {setAlertm(false); setMsgPld({ messg: "", title: "", severity: ""});}, 3000);
        setTimeout(() => {setAlerte(false); setMsgPld({ messg: "", title: "", severity: ""});}, 3000);
            } else { 
                setAlerte(true);
                showAlert("fal");
                console.log("Faltan agregar datos en el formulario")
            };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errrors = {};
        switch (tabla) {
            case "Libros" :
            case "Más deportes":
                setErrors(validateLibro(bookDetails));
                errrors = validateLibro(bookDetails);
                enviarProd(errrors);
                break;
                case "Guías México" :
                case "Guías Argentina":
                case "Guías Internacionales":
                    setErrors(validateGuia(guiaDetails));
                    errrors = validateGuia(guiaDetails);
                    enviarProd(errrors);
                    break;
                case "Rock":
                case "Automovilismo":
                case "Y también...":
                    setErrors(validateOtros(otrosDetails));
                    errrors = validateOtros(otrosDetails);
                    enviarProd(errrors);
                    break;
                case "Revistas":
                    setErrors(validateOtros(revistasDetails));
                    errrors = validateOtros(revistasDetails);
                    enviarProd(errrors);
                    break;
                default:
                break;
        }
    }
    
    const handleImageUpload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `images/${name}.${extension}`;
        //const key = `images/${uuidv4()}${name}.${extension}`;
        try {
            // Upload the file to s3 with public access level. 
            await uploadData({key, file, 
                options: {
                level: 'public',
                contentType: file.type
        }});
            // Retrieve the uploaded file to display
            const image = await downloadData({key, options: { level: 'public' }}).result;
            setImage(image);
 //           setBookDetails({ ...bookDetails, image: url });
        } catch (err) {
            console.log(err);
        }
    }

    const handleImageUploadRevistas = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `imgrevistas/${name}.${extension}`;
        //const key = `images/${uuidv4()}${name}.${extension}`;
        try {
            // Upload the file to s3 with public access level. 
            await uploadData({key, file, 
                options: {
                level: 'public',
                contentType: file.type
        }});
            // Retrieve the uploaded file to display
            const image = await downloadData({key, options: { level: 'public' }}).result;
            setImage(image);
 //           setBookDetails({ ...bookDetails, image: url });
        } catch (err) {
            console.log(err);
        }
    }

    const handleImage2Upload = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const extension = file.name.split(".")[1];
        const name = file.name.split(".")[0];
        const key = `images/${name}.${extension}`;
//        const url = `https://${bucket}.s3.${region}.amazonaws.com/images/${key}`
        try {
            // Upload the file to s3 with public access level. 
            await uploadData(key, file, {
                level: 'public',
                contentType: file.type
            });
            // Retrieve the uploaded file to display
            const image = await downloadData(key, { level: 'public' })
            setImage2(image);
 //           setBookDetails({ ...bookDetails, image2: url });
        } catch (err) {
            console.log(err);
        }
    }

     return (
         <>
        {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
        <section>
        <form className="form-wrapper">
            <h2>Agregar Libro / Revista</h2>
            <div className="form-fields">
                <div className="title-form">
                    <h3><label htmlFor="title">Tabla</label>
                    <select
                        name="producto"
                        type="producto"
                        placeholder="tabla de base de datos"
                        value={tabla}
                        onChange={(e) => setTabla(e.target.value )}
                        required
                    >
                    <option>Revistas</option>
                    <option>Libros</option>
                    <option>Más deportes</option>
                    <option>Automovilismo</option>
                    <option>Guías México</option>
                    <option>Guías Argentina</option>
                    <option>Guías Internacionales</option>
                    <option>Rock</option>
                    <option>Y también...</option>
                    </select>
                    </h3><br />
                </div>
              <div className={tabla === "Libros" || tabla === "Más deportes" ? "show" : "hide"}>
                <div className="form-image">
                <p>Imagen baja resolución (XXXXXX.jpg): 
                 {image ? <img className="image-preview" src={image} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImageUpload(e)} />}</p>
            <p>Imagen alta resolución (XXXXXXg.jpg): 
                 {image2 ? <img className="image-preview" src={image2} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImage2Upload(e)} />}</p>
            <br />
            </div>
            <div className="title-form">
            <p><label htmlFor="portada">Nombre del archivo de la foto (XXXXXX.jpg):</label>
                    <input
                        name="portada"
                        type="title"
                        placeholder="Agrega nombre de foto"
                        value={bookDetails.portada}
                        onChange={(e) => setBookDetails({ ...bookDetails, portada: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>               
                <div className="title-form">
                    <p><label htmlFor="titulo">Título:</label>
                    <input
                        name="titulo"
                        type="title"
                        placeholder="Agrega título del libro"
                        className= {errors.titulo ? ("border-error"):("")}
                        value={bookDetails.titulo}
                        onChange={(e) => setBookDetails({ ...bookDetails, titulo: e.target.value })}
                        required
                    /></p>
                    {errors.titulo && <p className="errorform">{errors.titulo}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="tituloMin">Título en minúsculas y sin acentos:</label>
                    <input
                        name="tituloMin"
                        type="title"
                        placeholder="Agrega título del libro"
                        className= {errors.tituloMin ? ("border-error"):("")}
                        value={bookDetails.tituloMin}
                        onChange={(e) => setBookDetails({ ...bookDetails, tituloMin: e.target.value })}
                        required
                    /></p>
                    {errors.tituloMin && <p className="errorform">{errors.tituloMin}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="autor">Autor:</label>
                    <input
                        name="autor"
                        type="title"
                        placeholder="Agrega nombre del autor"
                        className= {errors.autor ? ("border-error"):("")}
                        value={bookDetails.autor}
                        onChange={(e) => setBookDetails({ ...bookDetails, autor: e.target.value })}
                        required
                    /></p>
                    {errors.autor && <p className="errorform">{errors.autor}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="autorMin">Autor en minúsculas y sin acentos:</label>
                    <input
                        name="autorMin"
                        type="title"
                        placeholder="Agrega nombre del autor"
                        className= {errors.autorMin ? ("border-error"):("")}
                        value={bookDetails.autorMin}
                        onChange={(e) => setBookDetails({ ...bookDetails, autorMin: e.target.value })}
                        required
                    /></p>
                    {errors.autorMin && <p className="errorform">{errors.autorMin}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="nacautor">Nacionalidad del Autor:</label>
                    <input
                        name="nacautor"
                        type="title"
                        placeholder="Agrega nacionalidad del autor"
                        value={bookDetails.nacautor}
                        onChange={(e) => setBookDetails({ ...bookDetails, nacautor: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="contautor">Contacto del Autor:</label>
                    <input
                        name="contautor"
                        type="title"
                        placeholder="Agrega contacto del autor"
                        value={bookDetails.contautor}
                        onChange={(e) => setBookDetails({ ...bookDetails, contautor: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="pais">País de edición:</label>
                    <input
                        name="pais"
                        type="pais"
                        placeholder="Agrega país"
                        className= {errors.pais ? ("border-error"):("")}
                        value={bookDetails.pais}
                        onChange={(e) => setBookDetails({ ...bookDetails, pais: e.target.value })}
                        required
                    /></p>
                  {errors.pais && <p className="errorform">{errors.pais}</p>}      
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="editorial">Editorial:</label>
                    <input
                        name="editorial"
                        type="title"
                        placeholder="Agrega editorial"
                        value={bookDetails.editorial}
                        onChange={(e) => setBookDetails({ ...bookDetails, editorial: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>
                <div className='title-form'>
                <p><label htmlFor="paginas">Páginas (sólo números):</label>
                    <input
                        name="paginas"
                        type="title"
                        placeholder="Agrega cantidad de páginas"
                        className= {errors.paginas ? ("border-error"):("")}
                        value={bookDetails.paginas}
                        onChange={(e) => setBookDetails({ ...bookDetails, paginas: e.target.value })}
                        required
                    /></p>
                    {errors.paginas && <p className="errorform">{errors.paginas}</p>}                   
                    <br />
                </div>
                <div className='title-form'>
                <p><label htmlFor="tiraje">Tiraje (X,XXX ejemplares):</label>
                    <input
                        name="tiraje"
                        type="title"
                        placeholder="Agrega tiraje"
                        className= {errors.title ? ("border-error"):("")}
                        value={bookDetails.tiraje}
                        onChange={(e) => setBookDetails({ ...bookDetails, tiraje: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>        
                <div className='title-fecha'>
                <p><label htmlFor="fechadia">Fecha día (0 si no hay):</label>
                    <input
                        name="fechadia"
                        type="title"
                        placeholder="Día"
                        className= {errors.fechadia ? ("border-error"):("")}
                        value={bookDetails.diaedicion}
                        onChange={(e) => setBookDetails({ ...bookDetails, diaedicion: e.target.value })}
                        required
                    />
                    <label htmlFor="fechames">Fecha mes (0 si no hay):</label>
                    <input
                        name="fechames"
                        type="title"
                        placeholder="Mes"
                        className= {errors.fechames ? ("border-error"):("")}
                        value={bookDetails.mesedicion}
                        onChange={(e) => setBookDetails({ ...bookDetails, mesedicion: e.target.value })}
                        required
                    />
                    <label htmlFor="fechaano">Fecha año:</label>
                    <input
                        name="fechaano"
                        type="fechaano"
                        placeholder="Año"
                        className= {errors.fechaano ? ("border-error"):("")}
                        value={bookDetails.anoedicion}
                        onChange={(e) => setBookDetails({ ...bookDetails, anoedicion: e.target.value })}
                        required
                    /></p>
                    {errors.fechadia && <p className="errorform">{errors.fechadia}</p>}
                    {errors.fechames && <p className="errorform">{errors.fechames}</p>}
                    {errors.fechaano && <p className="errorform">{errors.fechaano}</p>}
                    <br />
                </div>                                                                                                                
                <div className="description-form">
                    <p><label htmlFor="comentario">Comentario</label></p>
                    <p><textarea
                        name="comentario"
                        type="text"
                        rows="8"
                        placeholder="Agrega comentario"
                        value={bookDetails.comentario}
                        onChange={(e) => setBookDetails({ ...bookDetails, comentario: e.target.value })}
                        required
                    /></p><br />
                </div>
                <div className="description-form">
                    <p><label htmlFor="momento">Momento</label></p>
                    <p><textarea
                        name="momento"
                        type="text"
                        rows="8"
                        placeholder="Agrega momento"
                        value={bookDetails.momento}
                        onChange={(e) => setBookDetails({ ...bookDetails, momento: e.target.value })}
                        required
                    /></p><br />
                </div>
                <div className="description-form">
                    <p><label htmlFor="diceautor">Dice el autor</label></p>
                    <p><textarea
                        name="diceautor"
                        type="text"
                        rows="8"
                        placeholder="Agrega dice el autor"
                        value={bookDetails.diceautor}
                        onChange={(e) => setBookDetails({ ...bookDetails, diceautor: e.target.value })}
                        required
                    /></p><br />
                </div>
        </div>
        <div className={tabla === "Guías México" || tabla === "Guías Argentina" || tabla === "Guías Internacionales" ? "show" : "hide"}>
            <div className="form-image">
                <p>Imagen baja resolución (XXXXXX.jpg): 
                 {image ? <img className="image-preview" src={image} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImageUpload(e)} />}</p>
            <p>Imagen alta resolución (XXXXXXg.jpg): 
                 {image2 ? <img className="image-preview" src={image2} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImage2Upload(e)} />}</p>
            <br />
            </div>
            <div className="title-form">
            <p><label htmlFor="nomfoto">Nombre del archivo de la foto (XXXXXX.jpg):</label>
                    <input
                        name="nomfoto"
                        type="title"
                        placeholder="Agrega nombre de foto"
                        value={guiaDetails.nomfoto}
                        onChange={(e) => setGuiaDetails({ ...guiaDetails, nomfoto: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="indice">Indice (mexXX, argXX, intXX):</label>
                    <input
                        name="indice"
                        type="title"
                        placeholder="Agrega Indice de la guía (revisar en editar)"
                        className= {errors.indice ? ("border-error"):("")}
                        value={guiaDetails.indice}
                        onChange={(e) => setGuiaDetails({ ...guiaDetails, indice: e.target.value })}
                        required
                    /></p>
                    {errors.indice && <p className="errorform">{errors.indice}</p>}                   
                    <br />
                </div>               
                <div className="title-form">
                    <p><label htmlFor="titulo">Título:</label>
                    <input
                        name="titulo"
                        type="title"
                        placeholder="Agrega título de la guía"
                        className= {errors.titulo ? ("border-error"):("")}
                        value={guiaDetails.titulo}
                        onChange={(e) => setGuiaDetails({ ...guiaDetails, titulo: e.target.value })}
                        required
                    /></p>
                    {errors.titulo && <p className="errorform">{errors.titulo}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="institucion">Institución:</label>
                    <input
                        name="institucion"
                        type="title"
                        placeholder="Agrega institución"
                        className= {errors.institucion ? ("border-error"):("")}
                        value={guiaDetails.institucion}
                        onChange={(e) => setGuiaDetails({ ...guiaDetails, institucion: e.target.value })}
                        required
                    /></p>
                    {errors.institucion && <p className="errorform">{errors.institucion}</p>}                   
                    <br />
                </div>
            </div>
            <div className={tabla === "Rock" || tabla === "Y también..." || tabla === "Automovilismo" ? "show" : "hide"}>
            <div className="form-image">
                <p>Imagen baja resolución (XXXXXX.jpg): 
                 {image ? <img className="image-preview" src={image} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImageUpload(e)} />}</p>
                <p>Imagen alta resolución (XXXXXXg.jpg): 
                 {image2 ? <img className="image-preview" src={image2} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImage2Upload(e)} />}</p>
            <br />
            </div>
            <div className="title-form">
            <p><label htmlFor="portada">Nombre del archivo de la foto (XXXXXX.jpg):</label>
                    <input
                        name="portada"
                        type="title"
                        placeholder="Agrega nombre de foto"
                        value={otrosDetails.portada}
                        onChange={(e) => setOtrosDetails({ ...otrosDetails, portada: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>               
                <div className="title-form">
                    <p><label htmlFor="titulo">Título:</label>
                    <input
                        name="titulo"
                        type="title"
                        placeholder="Agrega título del libro"
                        className= {errors.titulo ? ("border-error"):("")}
                        value={otrosDetails.titulo}
                        onChange={(e) => setOtrosDetails({ ...otrosDetails, titulo: e.target.value })}
                        required
                    /></p>
                    {errors.titulo && <p className="errorform">{errors.titulo}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="categ">Categoría (sólo Automovilismo) (f1 o cart):</label>
                    <input
                        name="categ"
                        type="title"
                        placeholder="Agrega f1 o cart"
                        value={otrosDetails.categ}
                        onChange={(e) => setOtrosDetails({ ...otrosDetails, categ: e.target.value })}
                    /></p>
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="autor">Autor:</label>
                    <input
                        name="autor"
                        type="text"
                        placeholder="Agrega autor del libro"
                        className= {errors.autor ? ("border-error"):("")}
                        value={otrosDetails.autor}
                        onChange={(e) => setOtrosDetails({ ...otrosDetails, autor: e.target.value })}
                        required
                    /></p>
                    {errors.autor && <p className="errorform">{errors.autor}</p>}                   
                    <br />
                </div>
                <div className='title-fecha'>
                <p><label htmlFor="fechadia">Fecha día (0 si no hay):</label>
                    <input
                        name="fechadia"
                        type="title"
                        placeholder="Día"
                        className= {errors.fechadia ? ("border-error"):("")}
                        value={otrosDetails.diaedicion}
                        onChange={(e) => setOtrosDetails({ ...otrosDetails, diaedicion: e.target.value })}
                        required
                    />
                    <label htmlFor="fechames">Fecha mes (0 si no hay):</label>
                    <input
                        name="fechames"
                        type="title"
                        placeholder="Mes"
                        className= {errors.fechames ? ("border-error"):("")}
                        value={otrosDetails.mesedicion}
                        onChange={(e) => setOtrosDetails({ ...otrosDetails, mesedicion: e.target.value })}
                        required
                    />
                    <label htmlFor="fechaano">Fecha año:</label>
                    <input
                        name="fechaano"
                        type="title"
                        placeholder="Año"
                        className= {errors.fechaano ? ("border-error"):("")}
                        value={otrosDetails.anoedicion}
                        onChange={(e) => setOtrosDetails({ ...otrosDetails, anoedicion: e.target.value })}
                        required
                    /></p>
                    {errors.fechadia && <p className="errorform">{errors.fechadia}</p>}
                    {errors.fechames && <p className="errorform">{errors.fechames}</p>}
                    {errors.fechaano && <p className="errorform">{errors.fechaano}</p>}      
                    <br />
                </div> 
            </div>
            <div className={tabla === "Revistas" ? "show" : "hide"}>
            <div className="form-image">
                <p>Imagen media resolución (XXXXXX.jpg): 
                 {image ? <img className="image-preview" src={image} alt="" /> : <input
                         type="file"
                         accept="image/jpg"
                         onChange={(e) => handleImageUploadRevistas(e)} />}</p>
            <br />
            </div>
            <div className="title-form">
            <p><label htmlFor="portada">Nombre del archivo de la foto (XXXXXX.jpg):</label>
                    <input
                        name="portada"
                        type="title"
                        placeholder="Agrega nombre de foto"
                        value={revistasDetails.portada}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, portada: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>               
                <div className="title-form">
                    <p><label htmlFor="titulo">Título de la revista:</label>
                    <input
                        name="titulo"
                        type="title"
                        placeholder="Agrega título de la revista"
                        className= {errors.titulo ? ("border-error"):("")}
                        value={revistasDetails.titulo}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, titulo: e.target.value })}
                        required
                    /></p>
                    {errors.titulo && <p className="errorform">{errors.titulo}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="titulo">Título en minúsculas y sin acentos:</label>
                    <input
                        name="titulo"
                        type="title"
                        placeholder="Agrega título de la revista"
                        className= {errors.tituloMin ? ("border-error"):("")}
                        value={revistasDetails.tituloMin}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, tituloMin: e.target.value })}
                        required
                    /></p>
                    {errors.tituloMin && <p className="errorform">{errors.tituloMin}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="autor">Autor:</label>
                    <input
                        name="autor"
                        type="text"
                        placeholder="Agrega autor de la revista"
                        className= {errors.autor ? ("border-error"):("")}
                        value={revistasDetails.autor}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, autor: e.target.value })}
                        required
                    /></p>
                    {errors.autor && <p className="errorform">{errors.autor}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="autor">Autor en minúsculas y sin acentos:</label>
                    <input
                        name="autor"
                        type="text"
                        placeholder="Agrega autor de la revista"
                        className= {errors.autorMin ? ("border-error"):("")}
                        value={revistasDetails.autorMin}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, autorMin: e.target.value })}
                        required
                    /></p>
                    {errors.autorMin && <p className="errorform">{errors.autorMin}</p>}                   
                    <br />
                </div>
                <div className="title-form">
                    <p><label htmlFor="autor">Editorial:</label>
                    <input
                        name="autor"
                        type="text"
                        placeholder="Agrega editorial de la revista"
                        value={revistasDetails.editorial}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, editorial: e.target.value })}
                        required
                    /></p>
                    <br />
                </div>
                <div className='title-fecha'>
                <p><label htmlFor="fechadia">Fecha día (0 si no hay):</label>
                    <input
                        name="fechadia"
                        type="title"
                        placeholder="Día"
                        className= {errors.fechadia ? ("border-error"):("")}
                        value={revistasDetails.diaedicion}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, diaedicion: e.target.value })}
                        required
                    />
                    <label htmlFor="fechames">Fecha mes (0 si no hay):</label>
                    <input
                        name="fechames"
                        type="title"
                        placeholder="Mes"
                        className= {errors.fechames ? ("border-error"):("")}
                        value={revistasDetails.mesedicion}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, mesedicion: e.target.value })}
                        required
                    />
                    <label htmlFor="fechaano">Fecha año:</label>
                    <input
                        name="fechaano"
                        type="title"
                        placeholder="Año"
                        className= {errors.fechaano ? ("border-error"):("")}
                        value={revistasDetails.anoedicion}
                        onChange={(e) => setRevistasDetails({ ...revistasDetails, anoedicion: e.target.value })}
                        required
                    /></p>
                    {errors.fechadia && <p className="errorform">{errors.fechadia}</p>}
                    {errors.fechames && <p className="errorform">{errors.fechames}</p>}
                    {errors.fechaano && <p className="errorform">{errors.fechaano}</p>}      
                </div> 
                </div>
                <div className="bton-form">
                    <button className="bton-agregar" type= "submit" onClick={handleSubmit}>Agregar</button>
                    {Object.keys(errors).length !== 0 && <p className="errorform">Faltan agregar datos al formulario</p>}
                </div>
            </div>
        </form>
</section>
</>
    )
}

export default AgregarLibro;