import React, { useContext } from 'react';
import { BookContext } from '../../context/books';
import "./Autor.css";
import autorImg from "../../images/autor-img.jpg";

const Autor = () => {
  const { idioma } = useContext(BookContext);
  switch (idioma) {
    case "español":
  return (
    <section className='autor'>
      <div className='containerautor'>
        <div className='autor-text'>
          <h2>EL AUTOR</h2>
        </div>

        <div className='autor-wrapper'>
          <div className='autor-img'>
            <img src = {autorImg} alt = "" />
          </div>
          <div className='autor-text'>
            <h2 className='autor-title fs-26 ls-1'>Sobre Marcelo Assaf</h2>
              <p>Marcelo Víctor Assaf nació el 13 de julio de 1968 en Mar del Plata, Buenos  Aires, Argentina, a las 4 y 5 AM, en una noche tormentosa, donde ya  evidenciaba sus ganas de molestar a horas donde la mayoría descansa. Y  lo hizo de un modo original, porque no fue en una clínica, sino en un departamento de la calle Jujuy que funcionaba como casa de partos, ubicado en la acera frente a la que vivían sus progenitores. Por lo tanto su Madre, Nélida Martín, cruzó la calle caminando, acompañada de su Padre, Francisco Assaf, para preparar su llegada al mundo. </p>
              <p>El gusto por el futbol, así como por el automovilismo viene desde niño, tiempos donde se quedaba con las ganas de poder comprar todo lo que se publicaba. </p>
              <p>Sus inicios en el periodismo fueron en 1986 como colaborador del diario La Capital, en la sección "Columnas de la Juventud", donde estuvo 12 años escribiendo en materia de rock. En ese tiempo también escribió en  revistas, hizo radio y cable, estuvo en producción de recitales y festivales y trabajó en discográficas. </p>
              <p>Hasta  que se fue aburriendo y retornó a lo que le gustaba en la niñez, sintiendo que entraba a una segunda juventud cuando se dedicó de lleno a los deportes, integrando el programa "Magazine Deportivo" de FM Encuentro y revisando entre sus colecciones se reencontró con el fútbol y el automovilismo. El fanatismo por los libros de futbol fue creciendo gradualmente. Su  llegada a México se produjo el miércoles 16 de abril de 1997, momento en que comenzó a coleccionar la mayor cantidad posible de material, entre los que siempre destacaron: revistas, diarios, libros, videos, y en menor medida playeras, pins, banderines, vasos y otros objetos alusivos, que acepta de acuerdo a su precio. </p>
              <p>En México colaboró con los diarios El Universal, Reforma y Crónica, y con las revistas Deporte Internacional, Balón (de la que después fue director), Magazine Deportivo, Auto Mundo Deportivo, De Sangre Azul, Solo Mexicanos, Goooya, Sky, y Sports Magazine. También hizo radio (ABC y ACIR) e Internet. </p>
              <p>Desde 2006 regresó a la revista Futbol Total, donde es redactor, y colabora para el programa "Pulso Deportivo", de Radio Ultra (89.3) de la ciudad de Mérida, Yucatán. Además integra el CIHF (Centro de la Investigación de la Historia del Futbol), con sede en Buenos Aires, Argentina. </p>
              <p>Para el ha efectuado aportes en las revistas de la FIFA y la Conmebol, medios de Ecuador y Japón, y la Federación Internacional de Futbol de Historia y Estadísticas (IFFHS). </p>
          </div>
        </div>
      </div>
    </section>
  )
  case "english":
    return (
      <section className='autor'>
        <div className='containerautor'>
          <div className='section-title autor-text'>
            <h2>The author</h2>
          </div>
  
          <div className='autor-content grid'>
            <div className='autor-img'>
              <img src = {autorImg} alt = "" />
            </div>
            <div className='autor-text'>
              <h2 className='autor-title fs-26 ls-1'>About Marcelo Assaf</h2>
              <p>Marcelo Victor Assaf was born on July 13th, 1968 in Mar del Plata, Buenos Aires, Argentina, at 4:05 AM, in a stormy nigh, so he showed earlier his wishes to bother while most people rest. And he made it in an original way, cause it was not in a hospital, but in an apartment at Jujuy street that functined as house of births placed across the street where his parents livedd. Therefor his mother, Nelida Martin, cross the street walking together with his father, Francisco Assaf, to wait for his coming to this world. </p>
                <p>The liking for football, as well as motors begins at childhood age, times where he was eager to buy all new releases.</p>
                <p>His beginnings in journalism was in 1986 as a helper in La Capital newspaper, at the Youth Column section, where he worked for about 12 years writing about Rock. At that time he also wrote in magazines, made radio and cable TV, he was in production of shows and festivals and worked also in record companies.</p>
                <p>Till he got bored and returned to what he liked at childhood, feeling that he was entering a second youth when he fully got dedicated to sports, integrating the program Magazine Deportivo at FM Encuentro and checking out in between his colection he rediscover football and motors. The fanaticism for football books begun growing gradually. His arrival to Mexico came up on Wednesday April 16th, 1997, moment when he started to collect the most of stuff, among them: magazines, newspapers, books, videos and less t-shirts, pins, pennants, glasses and other objects of the matter, that he accepts depending on price.</p>
                <p>In Mexico he collaborated with newspapers El Universal, Reforma and Cronica, and with magazines Deporte Internacional, Balon (at which after he became director), Magazine Deportivo, Auto Mundo Deportivo, De Sangre Azul, Solo Mexicanos, Goooya, Sky, y Sports Magazine. Also he made radio(ABC and ACIR) and Internet.</p>
                <p>Since 2006 he came back to the magazine Futbol Total, where he is redactor, and collaborate for the program Pulso Deportivo at Radio Ultra (89.3) of Merida City, Yucatan. Also he is member of CIHF (Football History Researh Center), based in Buenos Aires, Argentina.</p>
                <p>For it he has made contributions for magazines of FIFA and Conmebol, media from Ecuador and Japam, and the International Federation of Football History and Statistics (IFFHS).</p>
            </div>
          </div>
        </div>
      </section>
    )
  default:
    break;
}
}

export default Autor