import React, { useContext } from 'react';
import { BookContext } from '../../context/books';
import "./Descargas.css";

const Descargas = () => {
  const { idioma } = useContext(BookContext);
  switch (idioma) {
    case "español":
  return (
    <section className='descargas'>
      <div className='container'>
          <div className='descargas-text'>
            <h2 className='descargas-title fs-26 ls-1'>Descargas - Enlaces</h2>
            <div className="vinul"> 
            <ul>
              <li>
                <p><a href="http://www.conmebol.com/es/revista" target="_blank" rel="noreferrer">Revista de la Conmebol</a></p>
              </li>
              <li>
                <p><a href="http://www.conmebol.com/libros" target="_blank" rel="noreferrer">Libros de la Conmebol</a></p>
              </li>
              <li>
                <p><a href="http://books.google.com.mx/books?id=DoGtMeqV-fAC&amp;lpg=PA160&amp;ots=7IF-4mAOn7&amp;hl=es&amp;pg=PP1#v=onepage&amp;q&amp;f=true" target="_blank" rel="noreferrer">Libro: Todo sobre la selección española de Felix Martialay</a></p>
              </li>
              <li>
                <p><a href="http://antorchadeportiva.com/?cat=184" target="_blank" rel="noreferrer">Revista Antorcha Deportiva de Guatemala</a></p>
              </li>
              <li>
                <p><a href="http://www.pochoweb.com/libro/#/1/" target="_blank" rel="noreferrer">Libro: Pasión por el Futbol de Ecuador</a></p>
              </li>
              <li>
                <p><a href="http://www.clubamerica.com.mx/pdf/guia-de-medios-final.pdf" target="_blank" rel="noreferrer">Guía de Medios Club América Clausura 2013</a></p>
              </li>
              <li>
                <p><a href="http://www.clubamerica.com.mx/docs/ca-guiamedios14.pdf" target="_blank" rel="noreferrer">Guía de Medios Club América Clausura 2014</a></p>
              </li>
              <li>
                <p><a href="http://www.fuerzamonarca.com/media/pdf/PasionM/2012102610466858.pdf" target="_blank" rel="noreferrer">Revista del Club Morelia </a></p>
              </li>
              <li>
                <p><a href="http://juanchoz94.wordpress.com/2007/02/11/historias-y-anecdotas-enciclopedia-del-cucuta-deportivo/" target="_blank" rel="noreferrer">Historia por año del Cucuta Deportivo</a></p>
              </li>
              <li>
                <p><a href="http://books.google.com.mx/books?id=e7Jrej-ddv8C&printsec=frontcover&source=gbs_ge_summary_r&cad=0#v=onepage&q&f=false" target="_blank" rel="noreferrer">Selección de Brasil</a></p>
              </li>
              <li>
                <p><a href="http://books.google.com.mx/books?id=N38fcax08dcC&printsec=frontcover#v=onepage&q&f=false" target="_blank" rel="noreferrer">Fluminense Football Club</a></p>
              </li>
              <li>
                <p><a href="https://books.google.com.br/books/descargas/Placar_Magazine.html?id=62E72n4n42wC&hl=es" target="_blank" rel="noreferrer">Revista PLACAR Diciembre 1986</a></p>
              </li>
              <li>
                <p><a href="https://books.google.com.mx/books?id=a2AjziakKB4C&printsec=frontcover&hl=es#v=onepage&q&f=false" target="_blank" rel="noreferrer">Libro Irlandés: Gaffers</a></p>
              </li>
            </ul>

            </div>
          </div>
      </div>
    </section>
  )
  case "english":
    return (
    <section className='descargas'>
      <div className='container'>
          <div className='descargas-text'>
            <h2 className='descargas-title fs-26 ls-1'>Books Downloads - Links</h2>
            <div className="vinul"> 
            <ul>
              <li>
                <p><a href="http://www.conmebol.com/es/revista" target="_blank" rel="noreferrer">Conmebol's Magazines</a></p>
              </li>
              <li>
                <p><a href="http://www.conmebol.com/libros" target="_blank" rel="noreferrer">Conmebol's Books</a></p>
              </li>
              <li>
                <p><a href="http://books.google.com.mx/books?id=DoGtMeqV-fAC&amp;lpg=PA160&amp;ots=7IF-4mAOn7&amp;hl=es&amp;pg=PP1#v=onepage&amp;q&amp;f=true" target="_blank" rel="noreferrer">Book: Todo sobre la selección española from Felix Martialay</a></p>
              </li>
              <li>
                <p><a href="http://antorchadeportiva.com/?cat=184" target="_blank" rel="noreferrer">Magazine Antorcha Deportiva from Guatemala</a></p>
              </li>
              <li>
                <p><a href="http://www.pochoweb.com/libro/#/1/" target="_blank" rel="noreferrer">Book: Pasión por el Futbol from Ecuador</a></p>
              </li>
              <li>
                <p><a href="http://www.clubamerica.com.mx/pdf/guia-de-medios-final.pdf" target="_blank" rel="noreferrer">Media Guide Club América Clausura 2013</a></p>
              </li>
              <li>
                <p><a href="http://www.clubamerica.com.mx/docs/ca-guiamedios14.pdf" target="_blank" rel="noreferrer">Media Guide Club América Clausura 2014</a></p>
              </li>
              <li>
                <p><a href="http://www.fuerzamonarca.com/media/pdf/PasionM/2012102610466858.pdf" target="_blank" rel="noreferrer">Magazine from Club Morelia </a></p>
              </li>
              <li>
                <p><a href="http://juanchoz94.wordpress.com/2007/02/11/historias-y-anecdotas-enciclopedia-del-cucuta-deportivo/" target="_blank" rel="noreferrer">Historia por año from Cucuta Deportivo</a></p>
              </li>
              <li>
                <p><a href="http://books.google.com.mx/books?id=e7Jrej-ddv8C&printsec=frontcover&source=gbs_ge_summary_r&cad=0#v=onepage&q&f=false" target="_blank" rel="noreferrer">Brazil National Team</a></p>
              </li>
              <li>
                <p><a href="http://books.google.com.mx/books?id=N38fcax08dcC&printsec=frontcover#v=onepage&q&f=false" target="_blank" rel="noreferrer">Fluminense Football Club</a></p>
              </li>
              <li>
                <p><a href="https://books.google.com.br/books/descargas/Placar_Magazine.html?id=62E72n4n42wC&hl=es" target="_blank" rel="noreferrer">PLACAR Magazine - December 1986</a></p>
              </li>
              <li>
                <p><a href="https://books.google.com.mx/books?id=a2AjziakKB4C&printsec=frontcover&hl=es#v=onepage&q&f=false" target="_blank" rel="noreferrer">Irish Book: Gaffers</a></p>
              </li>
            </ul>

            </div>
          </div>
      </div>
    </section>
  )
  default:
      break;
}
}

export default Descargas