//import React from 'react';

const validateLibro = (values) => {

    let errors = {};

    if (!values.titulo) {
        errors.titulo="Se requiere el título del libro"
    }
    if (!values.tituloMin) {
        errors.tituloMin="Se requiere el título del libro en minúsculas"
    }
    if (!values.autor) {
        errors.autor="Se requiere el autor del libro"
    }
    if (!values.autorMin) {
        errors.autorMin="Se requiere el autor del libro en minúsculas"
    }
    if (!values.paginas) {
        errors.paginas="Se requiere el número de páginas"
    }    else if (isNaN(values.paginas)){
        errors.paginas="Debe ser un número"
    } 
    if (!values.diaedicion) {
        errors.fechadia="Se requiere el número de día"
    }    else if (isNaN(values.diaedicion)){
        errors.fechadia="Debe ser un número"
    }   else if (values.diaedicion > 31 || values.diaedicion < 0) 
    {   errors.fechadia="Debe ser menor o igual que 31"}
    if (!values.mesedicion) {
        errors.fechames="Se requiere el número de mes"
    }    else if (isNaN(values.mesedicion)){
        errors.fechames="Debe ser un número"
    }   else if (values.mesedicion > 12 || values.mesedicion < 0) 
    {   errors.fechames="Debe ser menor o igual que 12"}    
    if (!values.anoedicion) {
        errors.fechaano="Se requiere el número de año"
    }    else if (isNaN(values.anoedicion)){
        errors.fechaano="Debe ser un número"
    }   else if (values.anoedicion >= 2050 || values.anoedicion < 1930) 
    {   errors.fechaano="Debe ser mayor o igual que 1930"}
  return errors;
};

const validateGuia = (values) => {

    let errors = {};

    if (!values.titulo) {
        errors.titulo="Se requiere el título de la guía"
    }
    if (!values.indice) {
        errors.indice="Se requiere el Indice de la guía en el formato mexXX, argXX o intXX"
    }
    if (!values.institucion) {
        errors.institucion="Se requiere la institución"
    }
  return errors;
};

const validateOtros = (values) => {

    let errors = {};

    if (!values.titulo) {
        errors.titulo="Se requiere el título del libro"
    }
    if (!values.autor) {
        errors.autor="Se requiere el nombre del autor"
    }
    if (!values.diaedicion) {
        errors.fechadia="Se requiere el número de día"
    }    else if (isNaN(values.diaedicion)){
        errors.fechadia="Debe ser un número"
    }   else if (values.diaedicion > 31 || values.diaedicion < 0) 
    {   errors.fechadia="Debe ser menor o igual que 31"}
    if (!values.mesedicion) {
        errors.fechames="Se requiere el número de mes"
    }    else if (isNaN(values.mesedicion)){
        errors.fechames="Debe ser un número"
    }   else if (values.mesedicion > 12 || values.mesedicion < 0) 
    {   errors.fechames="Debe ser menor o igual que 12"}    
    if (!values.anoedicion) {
        errors.fechaano="Se requiere el número de año"
    }    else if (isNaN(values.anoedicion)){
        errors.fechaano="Debe ser un número"
    }   else if (values.anoedicion >= 2050 || values.anoedicion < 1930) 
    {   errors.fechaano="Debe ser mayor o igual que 1930"}  
  return errors;
};

const validateCarrusel = (values) => {

    let errors = {};

    if (!values.nomfoto) {
        errors.nomfoto="Se requiere el nombre de archivo de la foto"
    }   else if (values.nomfoto.substr(values.nomfoto.length -4) !== ".jpg"){
        errors.nomfoto="El nombre del archivo de la foto debe terminar en .jpg"}
  return errors;
};


export { validateLibro, validateGuia, validateOtros, validateCarrusel };