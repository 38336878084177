import React, { useContext } from 'react';
import { BookContext } from '../../context/books';
import "./Razones.css";

const Razones = () => {
  const { idioma } = useContext(BookContext);
  switch (idioma) {
    case "español":
  return (
    <section className='razones'>
      <div className='container'>
          <div className='razones-text'>
            <h2 className='razones-title fs-26 ls-1'>LAS RAZONES POR LAS QUE EXISTE EL CUARTITO</h2>
                <p>Esta página intenta acercar los lanzamientos editoriales en materia de futbol que se han publicado en México hacia todo el mundo, considerando también en la muestra a países como Argentina, España y Chile con lo que existe una gran afinidad. Como aclaración, vale decir que no están todos los libros, tanto mexicanos o de otros países, y que no hay una  competencia con nadie en este aspecto.</p>

                <p>La idea, entre mil ideas, es mostrar los libros desde que comenzaron a publicarse al presente, quedando la página como un medio de difusión para los nuevos lanzamientos, y poder dejar como testimonio los eventos de presentaciones.</p>

                <p>El cuartito de los libros tiene publicaciones de Chivas y el América, Cruz Azul y Pumas, Pachuca y Toluca, Atlante y la Selección Mexicana, historia y estadística, biografías y anecdotario, el futbol como fenómeno social y como elemento de consumo, etc. en una serie interminable de temáticas que hacen a esta colección algo muy colorido.</p>

                <p>Dentro de los interminables agradecimientos que hay para hacer, y que son eternos, está el recuerdo permanente para los periodistas, escritores, historiadores y aficionados que en algún momento escribieron un libro y tuvieron la gentileza de acercarse a regalarlo o bien lo cedieron de buena gana.</p>

                <p>A cada uno va este sincero reconocimiento por haber publicado una obra, con el fin de transmitir sus conocimientos, como así también de sumar un elemento que sirve como compañía para entender al futbol o a los deportes en general.</p>

                <h1>Notas publicadas sobre esta página:</h1>

                <p>Página Medio Tiempo: <a href="http://www.mediotiempo.com/futbol/mexico/noticias/2009/07/16/marcelo-assaf-nos-invita-a-conocer-039el-cuartito-de-los-libros039" target="_blank" rel="noreferrer"><span>http://www.mediotiempo.com/futbol/mexico/noticias/2009/07/16/marcelo-assaf-nos-invita-a-conocer-039el-cuartito-de-los-libros039</span></a></p>

                <p>Futbolteca de MedioTiempo: <a href="http://futbolteca.mediotiempo.com/?p=2608" target="_blank" rel="noreferrer"><span>http://futbolteca.mediotiempo.com/?p=2608</span></a></p>
          </div>
      </div>
    </section>
  )
  case "english":
    return (
      <section className='razones'>
        <div className='container'>
            <div className='razones-text'>
              <h2 className='razones-title fs-26 ls-1'>THE REASONS WHY THE LITTLE ROOM EXISTS</h2>
                  <p>This site trys to show editorial releases of football that were published from Mexico to the world, also considering in this sampling countries as Argentina, Spain and Chile with which exists a high affinity. As clarification, it is worth to say that not all books are posted, mexican or from other countries and that there is no competition with any other site or page at this point.</p>
  
                  <p>The idea, among thousands, is to show the books that began to publish till present, remaining this site as a diffusion media for new releases, and to leave a testimony of the upcoming presentation events.</p>
  
                  <p>The Books Little Room has releases of Chivas and America, Cruz Azul and Pumas, Pachuca and Toluca, Atlante and the Mexican national team, history and statistics, biographies and collection of stories, football as a social phenomenon and as a consumption element, etc. in an endless series of topics that turns this collection very colorful.</p>
  
                  <p>Talking about thanks that it is a due to do and that are eternal, is the recount of journalists, writers, historians and fans that at some point wrote a book and had the gentleness of coming and give it as present or they yielded in a good way.</p>
  
                  <p>For each one is this acknowledgment for publishing a piece, with the purpose of transmitting his knowledge, and also to add an element that helps as a guide to understand football or sports in general.</p>
  
                  <h1>Write-ups published about this site:</h1>
  
                  <p>Medio Tiempo site: <a href="http://www.mediotiempo.com/futbol/mexico/noticias/2009/07/16/marcelo-assaf-nos-invita-a-conocer-039el-cuartito-de-los-libros039" target="_blank" rel="noreferrer"><span>http://www.mediotiempo.com/futbol/mexico/noticias/2009/07/16/marcelo-assaf-nos-invita-a-conocer-039el-cuartito-de-los-libros039</span></a></p>
  
                  <p>Medio Tiempo's Futbolteca: <a href="http://futbolteca.mediotiempo.com/?p=2608" target="_blank" rel="noreferrer"><span>http://futbolteca.mediotiempo.com/?p=2608</span></a></p>
            </div>
        </div>
      </section>
    )
  default:
      break;
}
}

export default Razones