/* eslint-disable */

export const listLibros = /* GraphQL */ `
  query ListLibros(
    $filter: ModelLibrosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLibros(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titulo
        tituloMin
        pais
        autor
        autorMin
        nacautor
        contautor
        editorial
        diaedicion
        mesedicion
        anoedicion
        tiraje
        paginas
        portada
        comentario
        momento
        diceautor
      }
    }
  }
`;

export const listGuiasargs = /* GraphQL */ `
  query ListGuiasargs(
    $filter: ModelGuiasargFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuiasargs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        indice
        titulo
        institucion
        nomfoto
      }
    }
  }
`;

export const listGuiasints = /* GraphQL */ `
  query ListGuiasints(
    $filter: ModelGuiasintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuiasints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        indice
        titulo
        institucion
        nomfoto
      }
    }
  }
`;

export const listGuiasmxes = /* GraphQL */ `
  query ListGuiasmxes(
    $filter: ModelGuiasmxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuiasmxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        indice
        titulo
        institucion
        nomfoto
       }
    }
  }
`;

export const listAutomovs = /* GraphQL */ `
  query ListAutomovs(
    $filter: ModelAutomovFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAutomovs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titulo
        autor
        diaedicion
        mesedicion
        anoedicion
        portada
        categ
      }
    }
  }
`;

export const listMasdeps = /* GraphQL */ `
  query ListMasdeps(
    $filter: ModelMasdepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMasdeps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titulo
        pais
        autor
        nacautor
        contautor
        editorial
        diaedicion
        mesedicion
        anoedicion
        tiraje
        paginas
        portada
        comentario
        momento
        diceautor
      }
    }
  }
`;

export const listRocks = /* GraphQL */ `
  query ListRocks(
    $filter: ModelRockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titulo
        autor
        editorial
        diaedicion
        mesedicion
        anoedicion
        portada
      }
    }
  }
`;

export const listTambiens = /* GraphQL */ `
  query ListTambiens(
    $filter: ModelTambienFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTambiens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titulo
        autor
        editorial
        diaedicion
        mesedicion
        anoedicion
        portada
      }
    }
  }
`;

export const listEventos = /* GraphQL */ `
  query ListEventos(
    $filter: ModelEventosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        indice
        titulo
        autor
        diaevento
        mesevento
        anoevento
        lugar
        fotografo
        resena
        reseng
        nomfoto
        numfotos
      }
    }
  }
`;

export const listRevistas = /* GraphQL */ `
  query ListRevistas(
    $filter: ModelRevistasFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRevistas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titulo
        tituloMin
        autor
        autorMin
        editorial
        diaedicion
        mesedicion
        anoedicion
        portada
      }
    }
  }
`;
export const getLibros = /* GraphQL */ `
  query GetLibros($id: ID!) {
    getLibros(id: $id) {
      id
      titulo
      pais
      autor
      nacautor
      contautor
      editorial
      diaedicion
      mesedicion
      anoedicion
      tiraje
      paginas
      portada
      comentario
      momento
      diceautor
    }
  }
`;


export const getGuiasmx = /* GraphQL */ `
  query GetGuiasmx($id: ID!) {
    getGuiasmx(id: $id) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;

export const getGuiasarg = /* GraphQL */ `
  query GetGuiasarg($id: ID!) {
    getGuiasarg(id: $id) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;

export const getGuiasint = /* GraphQL */ `
  query GetGuiasint($id: ID!) {
    getGuiasint(id: $id) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;

export const getAutomov = /* GraphQL */ `
  query GetAutomov($id: ID!) {
    getAutomov(id: $id) {
      id
      titulo
      autor
      diaedicion
      mesedicion
      anoedicion
      portada
      categ
    }
  }
`;

export const getMasdep = /* GraphQL */ `
  query GetMasdep($id: ID!) {
    getMasdep(id: $id) {
      id
      titulo
      pais
      autor
      nacautor
      contautor
      editorial
      diaedicion
      mesedicion
      anoedicion
      tiraje
      paginas
      portada
      comentario
      momento
      diceautor
    }
  }
`;

export const getRock = /* GraphQL */ `
  query GetRock($id: ID!) {
    getRock(id: $id) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada
    }
  }
`;

export const getTambien = /* GraphQL */ `
  query GetTambien($id: ID!) {
    getTambien(id: $id) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada
    }
  }
`;

export const getEventos = /* GraphQL */ `
  query GetEventos($id: ID!) {
    getEventos(id: $id) {
      id
      indice
      titulo
      autor
      diaevento
      mesevento
      anoevento
      lugar
      fotografo
      resena
      reseng
      nomfoto
      numfotos
    }
  }
`;

export const getVentas = /* GraphQL */ `
  query GetVentas($id: ID!) {
    getVentas(id: $id) {
      id
      indice
      titulo
      precio
      descripcion
      preciocant
      nomfoto
      numfotos
      observ
    }
  }
`;

export const getRevistas = /* GraphQL */ `
  query GetRevistas($id: ID!) {
    getRevistas(id: $id) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada
    }
  }
`;

export const listCarruselItems = /* GraphQL */ `
  query ListCarruselItems(
    $filter: ModelCarruselItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarruselItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        indice
        titulo
        nomfoto
      }
    }
  }
`;