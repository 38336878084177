import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loader";
import coverImg from "../../images/cover_not_found.jpg";
import "./VentasDetails.css";
import {FaArrowLeft} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import config from '../../aws-exports';
import { BookContext } from '../../context/books';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const VentasDetails = () => {
    const {idDet} = useParams();
    const [loading, setLoading] = useState(false);
    const [venta, setVenta] = useState({});
    const [fotos, setFotos] = useState(null);
    const navigate = useNavigate();
    const { getVenta, idioma } = useContext(BookContext);

    useEffect(() => {
        setLoading(true);
        async function getVentaDetails(){
            try{
                const data = await getVenta(idDet);
                if (data){
                    const {titulo, nomfoto, numfotos, descripcion, precio, preciocant, observ} = data;
                    const newVenta = {
                        titulo: titulo,
                        descripcion: descripcion,
                        precio: precio,
                        preciocant: preciocant,
                        observ: observ,
                        nomfoto: nomfoto,
                        numfotos: numfotos,
                    };
                    const imgs = [];
                    for (let i=1; i <= data.numfotos; i++) {
                    let agregar = `${i}g.jpg`;
                    let buscar = ".jpg";
                    let gr1 = data.nomfoto.split(buscar);
                    let completo = `https://${bucket}.s3.${region}.amazonaws.com/imgvtas/${gr1[0]}` + agregar;
                    imgs.push({id: i, complet: completo});
                    }
                    setVenta(newVenta);
                    setFotos(imgs);
                } else {
                    setVenta(null);
                }
                setLoading(false);
            } catch(error){
                console.log(error);
                setLoading(false);
            }
        }
        getVentaDetails();
    }, [getVenta, idDet, setFotos]);

    if(loading) return <Loading />;
    switch (idioma) {
      case "español":
    return (
        <section className='ventas-details'>
            <div className="container">
                <button type='button' className="flex flex-c back-btn" onClick={() => { setVenta(null); setFotos(null); navigate("/venta") }}>
                    <FaArrowLeft size = {22} />
                    <span className="fs-18 fw-6">Atrás</span>
                </button>
                <div className='ventas-details-content grid'>
                    <div className='ventas-details-info'>
                        <div className='ventas-details-item title'>
                            <span className='fw-6 fs-24'>{venta?.titulo}</span>
                        </div>
                        <div className='ventas-details-item'>
                            <span className='fw-6'>Precio: </span>
                           <span className='text-italic'>{venta?.precio}</span>
                        </div>
                        <div className='ventas-details-item'>
                            <span className='fw-6'>Precio por cantidad: </span>
                           <span className='text-italic'>{venta?.preciocant}</span>
                        </div>
                        <div className='ventas-details-item'>
                            <span className='fw-6 fs-12'>Descripción: </span>
                            <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: venta.descripcion }}></span>
                        </div>
                        <div className='ventas-details-item description'>
                        <span className='fw-6'>Observaciones: </span>
                           <span>{venta?.observ}</span>
                        </div>
                    </div>
                    <div className='ventas-details-img'>
                        {fotos ? fotos.map((item, index) => {
                             return ( <img key = {index} src = {item?.complet} alt = "cover img" /> )}) : <img src = {coverImg} alt = "cover img" />
                         }
                    </div>
                </div>
            </div>
        </section>
    )
    case "english":
        return (
            <section className='ventas-details'>
                <div className="container">
                    <button type='button' className="flex flex-c back-btn" onClick={() => { setVenta(null); setFotos(null); navigate("/venta") }}>
                        <FaArrowLeft size = {22} />
                        <span className="fs-18 fw-6">Back</span>
                    </button>
                    <div className='ventas-details-content grid'>
                        <div className='ventas-details-info'>
                            <div className='ventas-details-item title'>
                                <span className='fw-6 fs-24'>{venta?.titulo}</span>
                            </div>
                            <div className='ventas-details-item'>
                                <span className='fw-6'>Price: </span>
                               <span className='text-italic'>{venta?.precio}</span>
                            </div>
                            <div className='ventas-details-item'>
                                <span className='fw-6'>Price per quantity: </span>
                               <span className='text-italic'>{venta?.preciocant}</span>
                            </div>
                            <div className='ventas-details-item'>
                                <span className='fw-6 fs-12'>Description: </span>
                               <span className='text-italic fw-3 fs-12' dangerouslySetInnerHTML={{ __html: venta.descripcion }}></span>
                            </div>
                            <div className='ventas-details-item description'>
                            <span className='fw-6'>Observations: </span>
                               <span>{venta?.observ}</span>
                            </div>
                        </div>
                        <div className='ventas-details-img'>
                            {fotos ? fotos.map((item, index) => {
                                 return ( <img key = {index} src = {item?.complet} alt = "cover img" /> )}) : <img src = {coverImg} alt = "cover img" />
                             }
                        </div>
                    </div>
                </div>
            </section>
        )
    default:
            break;
}
}

export default VentasDetails;