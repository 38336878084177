import React, { useState } from 'react';
import { generateClient } from "aws-amplify/api";
//import Loading from "../../../components/Loader/Loader";
import { copyDBs } from "../../../api/mutations";
import AlertMsg from './alertMsg';

const client = generateClient({});

const CopiarDB = () => {

    const [alertm, setAlertm] = useState(false); //Controls Alert message 
    const [alerte, setAlerte] = useState(false); //Controls Alert error
    const [msgPld, setMsgPld] = useState({ messg: "", title: "", severity: ""}) //Controls Message
    const [dbOrigen, setDBOrigen] = useState("");
    const [dbDestino, setDBDestino] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
        const { data } = await client.graphql({
          query: copyDBs,
          variables: { input: { dBOrigen: dbOrigen, dBDestino: dbDestino } },
          authMode: "userPool" 
        })
        console.log (data); // Cambiar por mensaje de enviado correcto
        showAlert("copied");
        } catch (err) {
            console.log(err); //agregar que hubo error
            setAlerte(true);
            showAlert("");
            }
        }

    const showAlert = (opcion) => {
        setAlertm(true);
        if (!alerte) {
            switch (opcion) {
                case "copied":
                setMsgPld({messg: "La base se copió correctamente", title:"Base copiada", severity: "success" });
                break;
                case "agr":
                setMsgPld({messg: "El libro se agregó correctamente al carrusel", title:"Libro agregado al carrusel", severity: "success" });
                break;
                default:;
            }
        } else {
          setMsgPld({messg: "Base de datos no encontrada", title:"Error", severity: "error" });
          };
      };

     return (
         <>
        {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
        <section className="form-wrapper">
        <div className="form-center">
        <h3>Base de origen: <span className="white-bg">
        <input 
        type = "text" 
        className='form-control' 
        placeholder='Base origen' 
        value = {dbOrigen}
        onChange={(e) => setDBOrigen(e.target.value)} />
        </span></h3><br />
        <h3>Base destino: <span className="white-bg">
        <input 
        type = "text" 
        className='form-control' 
        placeholder='Base destino' 
        value = {dbDestino}
        onChange={(e) => setDBDestino(e.target.value)} />
        </span></h3>
                <div className="bton-form">
                    <br />
                    <button className="bton-agregar" type= "submit" onClick={handleSubmit}>Copiar base de datos</button>
                    {/*Object.keys(errors).length !== 0 && <p className="errorform">Faltan agregar datos al formulario</p>*/}
                </div>
        </div>
   </section>
  </>
 )
}

export default CopiarDB;