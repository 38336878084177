import React, { useContext, useState, useEffect } from 'react';
import { CarruselContext } from "../../../context/carruselContext";
import { v4 as uuidv4 } from 'uuid';
import { generateClient } from "aws-amplify/api";
import Loading from "../../../components/Loader/Loader";
import { validateCarrusel } from './validates';
import { createCarruselItems, updateCarruselItems } from "../../../api/mutations";
import { EditableText } from "./editableText";
import AlertMsg from './alertMsg';

const client = generateClient({});

const EditarCarrusel = () => {

    const [alertm, setAlertm] = useState(false); //Controls Alert message 
    const [alerte, setAlerte] = useState(false); //Controls Alert error
    const [msgPld, setMsgPld] = useState({ messg: "", title: "", severity: ""}) //Controls Message
    const {carrusel, loading, fetchCarrusel } = useContext(CarruselContext);
    const carrDetails = { indice: "", titulo: "", nomfoto: ""}
    const [carrDetailsNew, setCarrDetailsNew] = useState({ titulo: "", nomfoto: ""})
    const [errors, setErrors] = useState({});
    const [newIndice, setIndice] = useState("");
    const [newTitle, setTitle] = useState("");
    const [newFile, setFile] = useState("");

    const enviarProd = async (errs) => {
        if (Object.keys(errs).length === 0 ){
            let ind = carrusel.length < 10 ? "0"+(carrusel.length+1) : (carrusel.length+1);
            try {
                await client.graphql({
                    query: createCarruselItems, 
                    variables: { input: {id: uuidv4, indice: ind, ...carrDetailsNew} }
                })
                setCarrDetailsNew({ titulo: "", nomfoto: "" })
                showAlert("agr");
                fetchCarrusel();
              } catch (err) {
                console.log('error creating todo:', err);
                setAlerte(true);
                showAlert();
              }
        setTimeout(() => {setAlertm(false); setMsgPld({ messg: "", title: "", severity: ""});}, 3000);
        setTimeout(() => {setAlerte(false); setMsgPld({ messg: "", title: "", severity: ""});}, 3000);
            } else { console.log("Faltan agregar datos en el formulario")};
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let errrors = {};
                setErrors(validateCarrusel(carrDetailsNew));
                errrors = validateCarrusel(carrDetailsNew);
                enviarProd(errrors);
        };

    const clickUpd = async (event, id, indice, titulo, nomfoto) => {
        event.preventDefault();
        carrDetails.indice = (newIndice === "" ? (indice) : (newIndice));
        carrDetails.titulo = (newTitle === "" ? (titulo) : (newTitle));
        carrDetails.nomfoto = (newFile === "" ? (nomfoto) : (newFile)); 
        try {
            await client.graphql({
                query: updateCarruselItems,
                variables: { input: { id: id, ...carrDetails}},
                authMode: "userPool"
            })
            showAlert("upd");
        } catch (err) {
            console.log(err);
            setAlerte(true);
            showAlert("upd");
        }
        setTimeout(() => {setAlertm(false); setMsgPld({ messg: "", title: "", severity: ""});}, 3000);
        setTimeout(() => {setAlerte(false); setMsgPld({ messg: "", title: "", severity: ""});}, 3000);
    };

    const showAlert = (opcion) => {
        setAlertm(true);
        if (!alerte) {
            switch (opcion) {
                case "upd":
                setMsgPld({messg: "El libro se actualizó correctamente del carrusel", title:"Libro de carrusel actualizado", severity: "success" });
                break;
                case "agr":
                setMsgPld({messg: "El libro se agregó correctamente al carrusel", title:"Libro agregado al carrusel", severity: "success" });
                break;
                default:;
            }
        } else {
          setMsgPld({messg: "Error en la conexión a base de datos de carrusel", title:"Error", severity: "error" });
          };
      };

      useEffect (() => {
        fetchCarrusel();
}, [fetchCarrusel])

    if(loading) return <Loading />;

     return (
         <>
        {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
        <section className="">
        <div>
            {carrusel.map(({ id, indice, titulo, nomfoto }) => (
                <article key={id} value= {id} className="form-wrapper">
        <div className="form-center">
        <h3>Indice: <span className="white-bg"><EditableText 
        key={id} 
        value={newIndice=== "" ? (indice) : (newIndice)}
        placeholder="Indice"
        onChange={e => setIndice(e.target.value)}
        >
         </EditableText></span></h3>
        <h3>Título: <span className="white-bg"><EditableText 
        key={id} 
        value={newTitle=== "" ? (titulo) : (newTitle)}
        placeholder="Título"
        onChange={e => setTitle(e.target.value)}
        >
         </EditableText></span></h3>
        <p>Nombre del archivo de la foto: <span className="white-bg"><EditableText 
        key={id} 
        value={newFile=== "" ? (nomfoto) : (newFile)}
        placeholder="Nombre del archivo de la foto" 
        onChange={e => setFile(e.target.value)}
        >
         </EditableText></span></p>
        </div>
        <div className="bton-form">
        <button className="bton-agregar" type="submit" onClick = {(e) => clickUpd(e, id, indice, titulo, nomfoto)}>Actualizar item de Carrusel
        </button>
        </div>
        </article>
        ))}
     </div>
        <div className='form-wrapper'>
                <div className="title-form">
                    <p><label htmlFor="titulo">Título:</label>
                    <input
                        name="titulo"
                        type="title"
                        placeholder="Agrega título del libro"
                        value={carrDetailsNew.titulo}
                        onChange={(e) => setCarrDetailsNew({ ...carrDetailsNew, titulo: e.target.value })}
                        required
                    /></p>
                </div>
                <div className="title-form">
            <p><label htmlFor="nomfoto">Nombre del archivo de la foto (XXXXXX.jpg):</label>
                    <input
                        name="nomfoto"
                        type="title"
                        placeholder="Agrega nombre de foto"
                        className= {errors.nomfoto ? ("border-error"):("")}
                        value={carrDetailsNew.nomfoto}
                        onChange={(e) => setCarrDetailsNew({ ...carrDetailsNew, nomfoto: e.target.value })}
                        required
                    /></p>
                    {errors.nomfoto && <p className="errorform">{errors.nomfoto}</p>}      
                </div>               
                <div className="bton-form">
                    <br />
                    <button className="bton-agregar" type= "submit" onClick={handleSubmit}>Agregar item al Carrusel</button>
                    {Object.keys(errors).length !== 0 && <p className="errorform">Faltan agregar datos al formulario</p>}
                </div>
            </div>
   </section>
  </>
 )
}

export default EditarCarrusel;