import React from "react";
import BuscEdit from './buscEdit/buscEdit';
import SelecEdit from './selecEdit/selecEdit';
import EditarLibro from './editLibs/editLibs';
import './gridEdit.css';

const gridEdit = () => {
    return (
        <div className='gridedit-content'>
            <div className='gridedit-text'>
                <h2 className='gridedit-title fs-20 ls-1'>Editar libros/revistas</h2>
            </div>
                <div id="boxgeneral" className="gridedit-wrapper">
                        <div id="busc-lib-edit" className="busc-lib-edit">
                            <BuscEdit tablaB={"libros"} />
                        </div>
                        <div id="busc-rev-edit" className="busc-rev-edit">
                            <BuscEdit tablaB={"revistas"} />
                        </div>
                        <div id="selec-edit" className="select-revist">
                            <SelecEdit />
                        </div>
                </div>
                <div id="edicion-edit">
                        <EditarLibro />
                </div>

        </div>
    )
}

export default gridEdit;