import React, { useContext, useState } from 'react';
import { generateClient } from "aws-amplify/api";
import { BookContext } from '../../../../context/books';
import Loading from "../../../../components/Loader/Loader";
import { updateLibros, updateAutomov, updateGuiasmx, updateGuiasarg, updateGuiasint, updateRock, updateTambien, updateMasdep, updateRevistas } from '../../../../api/mutations';
import { EditableText, EditableDesc } from "../../components/editableText";
import { validateLibro, validateGuia, validateOtros } from '../../components/validates';
import AlertMsg from '../../components/alertMsg';

const client = generateClient({});

const EditarLibro = () => {

    const [alertm, setAlertm] = useState(false); //Controls Alert message 
    const [alerte, setAlerte] = useState(false); //Controls Alert error
    const [msgPld, setMsgPld] = useState({ messg: "", title: "", severity: ""}) //Controls Message
    const { books, guias, otros, revistas, tablaEdit, loading } = useContext(BookContext);
    const [bookDetails, setBookDetails] = useState({ titulo: "", tituloMin: "", autor: "", autorMin: "", nacautor: "", contautor: "", pais: "", editorial: "", tiraje: "", paginas: "", portada: "",  comentario: "", momento: "", diceautor: "", diaedicion: "", mesedicion: "", anoedicion: "" });
    const [guiaDetails, setGuiaDetails] = useState({ id: "", titulo: "", institucion: "", portada: "" });
    const [otrosDetails, setOtrosDetails] = useState({ titulo: "", categ: "", autor: "", diaedicion: "",  mesedicion: "", anoedicion: "", portada: "" });
    const [revistasDetails, setRevistasDetails] = useState({ titulo: "", tituloMin: "", autor: "", autorMin: "", editorial: "", portada: "", diaedicion: "", mesedicion: "", anoedicion: "" });
    const [errors, setErrors] = useState({});
    const [newTitulo] = useState("");
    const [newTituloMin] = useState("");
    const [newPortada] = useState("");
    const [newNomfoto] = useState("");
    const [newInst] = useState("");
    const [newComent] = useState("");
    const [newPaginas] = useState("");
    const [newPais] = useState("");
    const [newAutor] = useState("");
    const [newAutorMin] = useState("");
    const [newEditorial] = useState("");
    const [newNacautor] = useState("");
    const [newContautor] = useState("");
    const [newMomento] = useState("");
    const [newDiceautor] = useState("");
    const [newTiraje] = useState("");
    const [newCateg] = useState("");
    const [newDia] = useState("");
    const [newMes] = useState("");
    const [newAno] = useState("");
 
    const handleSubmit = async (e, index) => {
        e.preventDefault();
        let errrors = {};
        switch (tablaEdit) {
            case "Libros" :
            case "Más deportes":
                const boks = {
                    id: books[index].id,
                    portada: bookDetails.portada === "" ? books[index].portada : bookDetails.portada,
                    titulo: bookDetails.titulo === "" ? books[index].titulo : bookDetails.titulo,
                    tituloMin: bookDetails.tituloMin === "" ? books[index].tituloMin : bookDetails.tituloMin,
                    autor: bookDetails.autor === "" ? books[index].autor : bookDetails.autor,
                    autorMin: bookDetails.autorMin === "" ? books[index].autorMin : bookDetails.autorMin,
                    nacautor: bookDetails.nacautor === "" ? books[index].nacautor : bookDetails.nacautor,
                    contautor: bookDetails.contautor === "" ? books[index].contautor : bookDetails.contautor,
                    pais: bookDetails.pais === "" ? books[index].pais : bookDetails.pais,
                    paginas: bookDetails.paginas === "" ? books[index].paginas : bookDetails.paginas,
                    tiraje: bookDetails.tiraje === "" ? books[index].tiraje : bookDetails.tiraje,
                    diaedicion: bookDetails.diaedicion === "" ? books[index].diaedicion : bookDetails.diaedicion,
                    mesedicion: bookDetails.mesedicion === "" ? books[index].mesedicion : bookDetails.mesedicion,
                    anoedicion: bookDetails.anoedicion === "" ? books[index].anoedicion : bookDetails.anoedicion,
                    comentario: bookDetails.comentario === "" ? books[index].comentario : bookDetails.comentario,
                    momento: bookDetails.momento === "" ? books[index].momento : bookDetails.momento,
                    diceautor: bookDetails.diceautor === "" ? books[index].diceautor : bookDetails.diceautor,
                }
                setErrors(validateLibro(boks));
                errrors = validateLibro(boks);
                console.log(errrors);
                clickUpd(e, errrors, boks);
                break;
            case "Guías México" :
            case "Guías Argentina":
            case "Guías Internacionales":
                const guis = {
                    id: guias[index].id,
                    nomfoto: guiaDetails[index].nomfoto === "" ? guias[index].nomfoto : guiaDetails.nomfoto,
                    titulo: guiaDetails[index].titulo === "" ? guias[index].titulo : guiaDetails.titulo,
                    institucion: guiaDetails[index].institucion === "" ? guias[index].institucion : guiaDetails.institucion,
                }
                setErrors(validateGuia(guis));
                errrors = validateGuia(guis);
                clickUpd(e, errrors, guis);
                break;
            case "Rock":
            case "Automovilismo":
            case "Y también...":
                const otrs = {
                    id: otros[index].id,
                    portada: otrosDetails.portada === "" ? otros[index].portada : otrosDetails.portada,
                    titulo: otrosDetails.titulo === "" ? otros[index].titulo : otrosDetails.titulo,
                    autor: otrosDetails.autor === "" ? otros[index].autor : otrosDetails.autor,
                    categ: otrosDetails.categ === "" ? otros[index].categ : otrosDetails.categ,
                    diaedicion: otrosDetails.diaedicion === "" ? otros[index].diaedicion : otrosDetails.diaedicion,
                    mesedicion: otrosDetails.mesedicion === "" ? otros[index].mesedicion : otrosDetails.mesedicion,
                    anoedicion: otrosDetails.anoedicion === "" ? otros[index].anoedicion : otrosDetails.anoedicion,
                }
                setErrors(validateOtros(otrs));
                errrors = validateOtros(otrs);
                clickUpd(e, errrors, otrs);
                break;
            case "Revistas":
                const revs = {
                    id: revistas[index].id,
                    portada: revistasDetails.portada === "" ? revistas[index].portada : revistasDetails.portada,
                    titulo: revistasDetails.titulo === "" ? revistas[index].titulo : revistasDetails.titulo,
                    tituloMin: revistasDetails.tituloMin === "" ? revistas[index].tituloMin : revistasDetails.tituloMin,
                    autor: revistasDetails.autor === "" ? revistas[index].autor : revistasDetails.autor,
                    autorMin: revistasDetails.autorMin === "" ? revistas[index].autorMin : revistasDetails.autorMin,
                    diaedicion: revistasDetails.diaedicion === "" ? revistas[index].diaedicion : revistasDetails.diaedicion,
                    mesedicion: revistasDetails.mesedicion === "" ? revistas[index].mesedicion : revistasDetails.mesedicion,
                    anoedicion: revistasDetails.anoedicion === "" ? revistas[index].anoedicion : revistasDetails.anoedicion,
                }
                setErrors(validateOtros(revs));
                errrors = validateOtros(revs);
                clickUpd(e, errrors, revs);
                break;
            default:
            break;
        }
    }

    const clickUpd = async (event, errs, data) => {
        event.preventDefault();
        if (Object.keys(errs).length === 0 ){
            let query = {};
            let input;
            switch (tablaEdit) {
                case "Revistas":
                    query = updateRevistas;
                    input = data;
                    break;
                case "Libros":
                    query = updateLibros;
                    input = data;
                    break;
                case "Más deportes":  
                    query = updateMasdep;
                    input = data;
                    break;
                case "Automovilismo":  
                    query = updateAutomov;
                    input = data;
                    break;
                case "Guías México":  
                    query = updateGuiasmx;
                    input = data;
                    break;                
                case "Guías Argentina":  
                    query = updateGuiasarg;
                    input = data;
                    break;                
                case "Guías Internacionales":  
                    query = updateGuiasint;
                    input = data;
                    break;                
                case "Rock":  
                    query = updateRock;
                    input = data;
                    break;                
                case "Y también...":  
                    query = updateTambien;
                    input = data;
                    break;                
                default:
                  break;
            }
            try {
                await client.graphql({
                    query: query, 
                    variables: { input: input },
                    authMode: "userPool"
                })
                showAlert("upd");
              } catch (err) {
                console.log('error creating todo:', err);
                setAlerte(true);
                showAlert(err);
              }
        setTimeout(() => {setAlertm(false); setMsgPld({ messg: "", title: "", severity: ""});}, 3000);
        setTimeout(() => {setAlerte(false); setMsgPld({ messg: "", title: "", severity: ""});}, 3000);
            } else { 
                setAlerte(true);
                showAlert("fal");
                console.log("Faltan agregar datos en el formulario")
            };
    };

    const showAlert = (opcion) => {
        setAlertm(true);
        if (!alerte) {
            switch (opcion) {
                case "upd":
                setMsgPld({messg: "El libro se actualizó correctamente", title:"Libro actualizado", severity: "success" });
                break;
                case "err":
                setMsgPld({messg: "Error en la conexión a base de datos de libros", title:"Error", severity: "error" });
                break;
                case "fal":
                setMsgPld({messg: "Faltan agregar datos al formulario", title:"Aviso", severity: "warning" });
                break;
                default:;
            }
        } else {
          setMsgPld({messg: "Error en la conexión a base de datos de libros", title:"Error", severity: "error" });
          };
      };

      if(loading) return <Loading />;

     return (
         <>
        {alertm ? <AlertMsg msg= {msgPld}></AlertMsg> : ("")} 
        <section className="books">
        <div className={tablaEdit === "Libros" || tablaEdit === "Más deportes" ? "show" : "hide"}>
           {books.map(({ id, titulo, tituloMin, pais, autor, autorMin, nacautor, contautor, editorial, paginas, tiraje, portada, diaedicion, mesedicion, anoedicion, comentario, momento, diceautor }, index) => (
              <article key={id} value= {id} className="book">
        <div>
        <div>
        <h3>Título: <span className="white-bg"><EditableText 
        key={id} 
        value={newTitulo=== "" ? (titulo) : (newTitulo)}
        placeholder="Título"
        className= {errors.titulo ? ("border-error"):("")}
        onChange={e => setBookDetails({...bookDetails, titulo: (e.target.value === "" ? (titulo) : e.target.value)})}
        >
         </EditableText></span></h3>
         {errors.titulo && <p className="errorform">{errors.titulo}</p>}    
         <h3>Título en minúsculas y sin acentos: <span className="white-bg"><EditableText 
        key={id} 
        value={newTituloMin=== "" ? (tituloMin) : (newTituloMin)}
        placeholder="Título"
        className= {errors.tituloMin ? ("border-error"):("")}
        onChange={e => setBookDetails({...bookDetails, tituloMin: (e.target.value === "" ? (tituloMin) : e.target.value)})}
        >
         </EditableText></span></h3>
         {errors.tituloMin && <p className="errorform">{errors.tituloMin}</p>}   
         <p className="fw-6 fs-16"><span>Nombre archivo foto (XXXXXX.jpg): <span className="white-bg"><EditableText 
        key={id} 
        value={newPortada=== "" ? (portada) : (newPortada)}
        placeholder="Portada"
        onChange={e => setBookDetails({...bookDetails, portada:(e.target.value === "" ? (portada) : e.target.value)})}
        >
        </EditableText></span></span>
         <span> País: <span className="white-bg"><EditableText 
        key={id} 
        value={newPais=== "" ? (pais) : (newPais)}
        placeholder="País"
        className= {errors.pais ? ("border-error"):("")}
        onChange={e => setBookDetails({...bookDetails, pais:(e.target.value === "" ? (pais) : e.target.value)})}
        >
        </EditableText></span></span></p>
        {errors.pais && <span className="errorform">{errors.pais}</span>}   
        <p className="fw-6 fs-16"><span>Autor: <span className="white-bg"><EditableText 
        key={id} 
        value={newAutor=== "" ? (autor) : (newAutor)}
        placeholder="Autor"
        className= {errors.autor ? ("border-error"):("")}
        onChange={e => setBookDetails({...bookDetails, autor:(e.target.value === "" ? (autor) : e.target.value)})}
        >
        </EditableText></span></span>
        {errors.autor && <span className="errorform">{errors.autor}</span>}    
        <span>Autor en minúsculas y sin acentos: <span className="white-bg"><EditableText 
        key={id} 
        value={newAutorMin=== "" ? (autorMin) : (newAutorMin)}
        placeholder="Autor"
        className= {errors.autorMin ? ("border-error"):("")}
        onChange={e => setBookDetails({...bookDetails, autorMin:(e.target.value === "" ? (autorMin) : e.target.value)})}
        >
        </EditableText></span></span></p>
        <p className="fw-6 fs-16"><span>Nacionalidad del autor: <span className="white-bg"><EditableText 
        key={id} 
        value={newNacautor=== "" ? (nacautor) : (newNacautor)}
        placeholder="Nacionalidad del autor"
        onChange={e => setBookDetails({...bookDetails, nacautor:(e.target.value === "" ? (nacautor) : e.target.value)})}
        >
        </EditableText></span></span>
        {errors.autorMin && <p className="errorform">{errors.autorMin}</p>}   
        <span> Contacto del autor: <span className="white-bg"><EditableText 
        key={id} 
        value={newContautor=== "" ? (contautor) : (newContautor)}
        placeholder="Contacto del autor"
        onChange={e => setBookDetails({...bookDetails, contautor:(e.target.value === "" ? (contautor) : e.target.value)})}
        >
        </EditableText></span></span></p>
        <h4>Editorial: <span className="white-bg"><EditableText 
        key={id} 
        value={newEditorial=== "" ? (editorial) : (newEditorial)}
        placeholder="Editorial"
        onChange={e => setBookDetails({...bookDetails, editorial:(e.target.value === "" ? (editorial) : e.target.value)})}
        >
        </EditableText></span></h4>
        <p><span>Páginas: <span className="white-bg"><EditableText 
        key={id} 
        value={newPaginas=== "" ? (paginas) : (newPaginas)}
        placeholder="Páginas"
        className= {errors.paginas ? ("border-error"):("")}
        onChange={e => setBookDetails({...bookDetails, paginas:(e.target.value === "" ? (paginas) : e.target.value)})}
        >
        </EditableText></span></span>
        {errors.paginas && <span className="errorform">{errors.paginas}</span>}  
        <span> Tiraje: <span className="white-bg"><EditableText 
        key={id} 
        value={newTiraje=== "" ? (tiraje) : (newTiraje)}
        placeholder="Tiraje"
        onChange={e => setBookDetails({...bookDetails, tiraje:(e.target.value === "" ? (tiraje) : e.target.value)})}
        >
        </EditableText></span></span>
        <span> Dia: <span className="white-bg"><EditableText 
        key={id} 
        value={newDia=== "" ? (diaedicion) : (newDia)}
        className= {errors.fechadia ? ("border-error"):("")}
        placeholder="Día"
        onChange={e => setBookDetails({...bookDetails, diaedicion:(e.target.value === "" ? (diaedicion) : e.target.value)})}
        >
        </EditableText></span></span>
        <span> Mes: <span className="white-bg"><EditableText 
        key={id} 
        value={newMes=== "" ? (mesedicion) : (newMes)}
        className= {errors.fechames ? ("border-error"):("")}
        placeholder="Mes"
        onChange={e => setBookDetails({...bookDetails, mesdicion:(e.target.value === "" ? (mesedicion) : e.target.value)})}
        >
        </EditableText></span></span>
        <span> Año: <span className="white-bg"><EditableText 
        key={id} 
        value={newAno=== "" ? (anoedicion) : (newAno)}
        className= {errors.fechaano ? ("border-error"):("")}
        placeholder="Mes"
        onChange={e => setBookDetails({...bookDetails, anoedicion:(e.target.value === "" ? (anoedicion) : e.target.value)})}
        >
        </EditableText></span></span></p>
        {errors.fechadia && <p className="errorform">{errors.fechadia}</p>}
        {errors.fechames && <p className="errorform">{errors.fechames}</p>}
        {errors.fechaano && <p className="errorform">{errors.fechaano}</p>}
        <p>Comentario: <span className="white-bg"><EditableDesc 
        key={id} 
        value={newComent=== "" ? (comentario) : (newComent)}
        placeholder="Comentario" 
        onChange={e => setBookDetails({...bookDetails, comentario:(e.target.value === "" ? (comentario) : e.target.value)})}
        >
         </EditableDesc></span></p>
         <p>Momento: <span className="white-bg"><EditableDesc 
        key={id} 
        value={newMomento=== "" ? (momento) : (newMomento)}
        placeholder="Momento" 
        onChange={e => setBookDetails({...bookDetails, momento:(e.target.value === "" ? (momento) : e.target.value)})}
        >
         </EditableDesc></span></p>
         <p>Dice el autor: <span className="white-bg"><EditableDesc 
        key={id} 
        value={newDiceautor=== "" ? (diceautor) : (newDiceautor)}
        placeholder="Dice el autor" 
        onChange={e => setBookDetails({...bookDetails, diceautor:(e.target.value === "" ? (diceautor) : e.target.value)})}
        >
         </EditableDesc></span></p>
        </div>
        <div className="bton-form">
            <button className="bton-agregar" type="submit" onClick = {(e) => handleSubmit(e, index)}>Actualizar Libro</button>
        </div>
      </div>
    </article>
    ))}
   </div>
   <div className={tablaEdit === "Guías México" || tablaEdit === "Guías Argentina" || tablaEdit === "Guías Internacionales" ? "show" : "hide"}>
           {guias.map(({ id, titulo, nomfoto, institucion }, index) => (
              <article key={id} value= {id} className="book">
        <div>
        <div>
         {errors.id && <p className="errorform">{errors.id}</p>}  
        <h3>Título: <span className="white-bg"><EditableText 
        key={id} 
        value={newTitulo=== "" ? (titulo) : (newTitulo)}
        placeholder="Título"
        className= {errors.titulo ? ("border-error"):("")}
        onChange={e => setGuiaDetails({...guiaDetails, titulo:(e.target.value === "" ? (titulo) : e.target.value)})}
        >
         </EditableText></span></h3>
         {errors.titulo && <p className="errorform">{errors.titulo}</p>}   
        <p className="fw-6 fs-16"><span>Nombre archivo foto (XXXXXX.jpg): <span className="white-bg"><EditableText 
        key={id} 
        value={newNomfoto=== "" ? (nomfoto) : (newNomfoto)}
        placeholder="Portada"
        onChange={e => setGuiaDetails({...guiaDetails, nomfoto:(e.target.value === "" ? (nomfoto) : e.target.value)})}
        >
        </EditableText></span></span></p>
        <p className="fw-6 fs-16"><span>Institución: <span className="white-bg"><EditableText 
        key={id} 
        value={newInst=== "" ? (institucion) : (newInst)}
        placeholder="Institución"
        className= {errors.institucion ? ("border-error"):("")}
        onChange={e => setGuiaDetails({...guiaDetails, institucion:(e.target.value === "" ? (institucion) : e.target.value)})}
        >
        </EditableText></span></span></p>
        {errors.institucion && <p className="errorform">{errors.institucion}</p>}  
        </div>
        <div className="bton-form">
            <button className="bton-agregar" type="submit" onClick = {(e) => handleSubmit(e, index)}>Actualizar Guia</button>
        </div>
      </div>
    </article>
     ))}
    </div>
        <div className={tablaEdit === "Rock" || tablaEdit === "Y también..." || tablaEdit === "Automovilismo" ? "show" : "hide"}>
        {otros.map(({ id, titulo, portada, autor, categ, diaedicion, mesedicion, anoedicion }, index) => (
              <article key={id} value= {id} className="book">
        <div>
        <div>
        <h3>Título: <span className="white-bg"><EditableText 
        key={id} 
        value={newTitulo=== "" ? (titulo) : (newTitulo)}
        placeholder="Título"
        className= {errors.titulo ? ("border-error"):("")}
        onChange={e => setOtrosDetails({...otrosDetails, titulo:(e.target.value === "" ? (titulo) : e.target.value)})}
        >
         </EditableText></span></h3>
         {errors.titulo && <p className="errorform">{errors.titulo}</p>} 
        <p className="fw-6 fs-16"><span>Nombre archivo foto (XXXXXX.jpg): <span className="white-bg"><EditableText 
        key={id} 
        value={newPortada=== "" ? (portada) : (newPortada)}
        placeholder="Portada"
        onChange={e => setOtrosDetails({...otrosDetails, portada:(e.target.value === "" ? (portada) : e.target.value)})}
        >
        </EditableText></span></span></p>
        <p className="fw-6 fs-16"><span>Categoría (sólo Automovilismo) (f1 o cart): <span className="white-bg"><EditableText 
        key={id} 
        value={newCateg=== "" ? (categ) : (newCateg)}
        placeholder="Categoria"
        onChange={e => setOtrosDetails({...otrosDetails, categ:(e.target.value === "" ? (categ) : e.target.value)})}
        >
        </EditableText></span></span></p>
        <p className="fw-6 fs-16"><span>Autor: <span className="white-bg"><EditableText 
        key={id} 
        value={newAutor=== "" ? (autor) : (newAutor)}
        placeholder="Autor"
        className= {errors.autor ? ("border-error"):("")}
        onChange={e => setOtrosDetails({...otrosDetails, autor:(e.target.value === "" ? (autor) : e.target.value)})}
        >
        </EditableText></span></span></p>
        {errors.autor && <p className="errorform">{errors.autor}</p>}   
        <p><span> Dia: <span className="white-bg"><EditableText 
        key={id} 
        value={newDia=== "" ? (diaedicion) : (newDia)}
        className= {errors.fechadia ? ("border-error"):("")}
        placeholder="Día"
        onChange={e => setOtrosDetails({...otrosDetails, diaedicion:(e.target.value === "" ? (diaedicion) : e.target.value)})}
        >
        </EditableText></span></span>
        <span> Mes: <span className="white-bg"><EditableText 
        key={id} 
        value={newMes=== "" ? (mesedicion) : (newMes)}
        className= {errors.fechames ? ("border-error"):("")}
        placeholder="Mes"
        onChange={e => setOtrosDetails({...otrosDetails, mesdicion:(e.target.value === "" ? (mesedicion) : e.target.value)})}
        >
        </EditableText></span></span>
        <span> Año: <span className="white-bg"><EditableText 
        key={id} 
        value={newAno=== "" ? (anoedicion) : (newAno)}
        className= {errors.fechaano ? ("border-error"):("")}
        placeholder="Mes"
        onChange={e => setOtrosDetails({...otrosDetails, anoedicion:(e.target.value === "" ? (anoedicion) : e.target.value)})}
        >
        </EditableText></span></span></p>
        {errors.fechadia && <p className="errorform">{errors.fechadia}</p>}
        {errors.fechames && <p className="errorform">{errors.fechames}</p>}
        {errors.fechaano && <p className="errorform">{errors.fechaano}</p>}
        </div>
        <div className="bton-form">
            <button className="bton-agregar" type="submit" onClick = {(e) => handleSubmit(e, index)}>Actualizar Libro</button>
        </div>
      </div>
    </article>
    ))}
   </div>
   <div className={tablaEdit === "Revistas" ? "show" : "hide"}>
           {revistas.map(({ id, titulo, tituloMin, autor, autorMin, editorial, portada, diaedicion, mesedicion, anoedicion }, index) => (
              <article key={id} value= {id} className="book">
        <div>
        <div>
        <p className="fw-6 fs-16"><span>Nombre archivo foto (XXXXXX.jpg): <span className="white-bg"><EditableText 
        key={id} 
        value={newPortada=== "" ? (portada) : (newPortada)}
        placeholder="Portada"
        onChange={e => setRevistasDetails({...revistasDetails, portada:(e.target.value === "" ? (portada) : e.target.value)})}
        >
        </EditableText></span></span></p>
        <h3>Título: <span className="white-bg"><EditableText 
        key={id} 
        value={newTitulo=== "" ? (titulo) : (newTitulo)}
        placeholder="Título"
        className= {errors.titulo ? ("border-error"):("")}
        onChange={e => setRevistasDetails({...revistasDetails, titulo:(e.target.value === "" ? (titulo) : e.target.value)})}
        >
         </EditableText></span></h3>
         {errors.titulo && <p className="errorform">{errors.titulo}</p>}   
         <h3>Título en minúsculas y sin acentos: <span className="white-bg"><EditableText 
        key={id} 
        value={newTituloMin=== "" ? (tituloMin) : (newTituloMin)}
        placeholder="Título"
        className= {errors.tituloMin ? ("border-error"):("")}
        onChange={e => setRevistasDetails({...revistasDetails, tituloMin:(e.target.value === "" ? (tituloMin) : e.target.value)})}
        >
         </EditableText></span></h3>
         {errors.tituloMin && <p className="errorform">{errors.tituloMin}</p>}   
        <p className="fw-6 fs-16"><span>Autor: <span className="white-bg"><EditableText 
        key={id} 
        value={newAutor=== "" ? (autor) : (newAutor)}
        placeholder="Autor"
        className= {errors.autor ? ("border-error"):("")}
        onChange={e => setRevistasDetails({...revistasDetails, autor:(e.target.value === "" ? (autor) : e.target.value)})}
        >
        </EditableText></span></span>
        {errors.autor && <span className="errorform">{errors.autor}</span>}  
        <span>Autor en minúsculas y sin acentos: <span className="white-bg"><EditableText 
        key={id} 
        value={newAutorMin=== "" ? (autorMin) : (newAutorMin)}
        placeholder="Autor"
        className= {errors.autorMin ? ("border-error"):("")}
        onChange={e => setRevistasDetails({...revistasDetails, autorMin:(e.target.value === "" ? (autorMin) : e.target.value)})}
        >
        </EditableText></span></span></p>
        {errors.autorMin && <span className="errorform">{errors.autorMin}</span>} 
        <h4>Editorial: <span className="white-bg"><EditableText 
        key={id} 
        value={newEditorial=== "" ? (editorial) : (newEditorial)}
        placeholder="Editorial"
        onChange={e => setRevistasDetails({...revistasDetails, editorial:(e.target.value === "" ? (editorial) : e.target.value)})}
        >
        </EditableText></span></h4>
        <p><span> Dia: <span className="white-bg"><EditableText 
        key={id} 
        value={newDia=== "" ? (diaedicion) : (newDia)}
        className= {errors.fechadia ? ("border-error"):("")}
        placeholder="Día"
        onChange={e => setRevistasDetails({...revistasDetails, diaedicion:(e.target.value === "" ? (diaedicion) : e.target.value)})}
        >
        </EditableText></span></span>
        <span> Mes: <span className="white-bg"><EditableText 
        key={id} 
        value={newMes=== "" ? (mesedicion) : (newMes)}
        className= {errors.fechames ? ("border-error"):("")}
        placeholder="Mes"
        onChange={e => setRevistasDetails({...revistasDetails, mesdicion:(e.target.value === "" ? (mesedicion) : e.target.value)})}
        >
        </EditableText></span></span>
        <span> Año: <span className="white-bg"><EditableText 
        key={id} 
        value={newAno=== "" ? (anoedicion) : (newAno)}
        className= {errors.fechaano ? ("border-error"):("")}
        placeholder="Mes"
        onChange={e => setRevistasDetails({...revistasDetails, anoedicion:(e.target.value === "" ? (anoedicion) : e.target.value)})}
        >
        </EditableText></span></span></p>
        {errors.fechadia && <p className="errorform">{errors.fechadia}</p>}
        {errors.fechames && <p className="errorform">{errors.fechames}</p>}
        {errors.fechaano && <p className="errorform">{errors.fechaano}</p>}
        </div>
        <div className="bton-form">
            <button className="bton-agregar" type="submit" onClick = {(e) => handleSubmit(e, index)}>Actualizar Revista</button>
        </div>
      </div>
    </article>
    ))}
   </div>
  </section>
 </>
)
}

export default EditarLibro;