import React, { useContext } from 'react';
import { BookContext } from '../../context/books';
import "./Links.css";

const Links = () => {
  const { idioma } = useContext(BookContext);
  switch (idioma) {
    case "español":
  return (
    <section className='links'>
      <div className='container'>
          <div className='links-text'>
            <h2 className='links-title fs-26 ls-1'>LINKS ÚTILES / PÁGINAS DE AMIGOS:</h2>
<h3><em>Páginas Oficiales</em></h3>
<div><a href="http://www.fifa.com" target="_blank" rel="noreferrer"><span>www.fifa.com</span></a></div>
<div><a href="http://www.uefa.com" target="_blank" rel="noreferrer"><span>www.uefa.com</span></a></div>
<div><a href="http://www.conmebol.com" target="_blank" rel="noreferrer"><span>www.conmebol.com</span></a></div>
<div><a href="http://www.concacaf.com" target="_blank" rel="noreferrer"><span>www.concacaf.com</span></a></div>
<div><a href="http://www.cafonline.com" target="_blank" rel="noreferrer"><span>www.cafonline.com</span></a></div>
<div><a href="http://www.the-afc.com" target="_blank" rel="noreferrer"><span>www.the-afc.com</span></a></div>
<div><a href="http://www.oceaniafootball.com" target="_blank" rel="noreferrer"><span>www.oceaniafootball.com</span></a></div>
<div><a href="http://www.femexfut.org.mx" target="_blank" rel="noreferrer"><span><strong>www.femexfut.org.mx</strong></span></a></div>
<div><a href="http://www.salvemosalfutbol.org" target="_blank" rel="noreferrer"><span>www.salvemosalfutbol.org</span></a></div>
<div><a href="http://catalogo.iib.unam.mx/F/792ITDY8T46TS76JA119H6472URNAMIY6TTVLTTBKVJX497HL2-28637?func=find-b&request=Futbol&x=40&y=7&find_code=WRD&adjacent=N&filter_code_2=WYR&filter_request_2=&filter_code_3=WYR&filter_request_3=&filter_code_1=WLN&filter_request_1=&filter_code_4=WFM&filter_request_4=" target="_blank" rel="noreferrer"><span id="lw_1248213604_6">Cat&aacute;logo de Libros de la UNAM</span></a></div>
<br></br><h3><em>Revistas de Futbol, Diarios y Canales</em></h3>
<div><a href="http://www.mediotiempo.com" target="_blank" rel="noreferrer"><span>www.mediotiempo.com</span></a></div>
<div><a href="http://www.goal.com" target="_blank" rel="noreferrer"><span>www.goal.com</span></a></div>
<div><a href="http://www.espn.com/" target="_blank" rel="noreferrer"><span>www.espn.com</span></a></div>
<div><a href="http://www.ole.com.ar" target="_blank" rel="noreferrer"><span>www.ole.com.ar</span></a></div>
<div><a href="http://www.marca.com" target="_blank" rel="noreferrer"><span>www.marca.com</span></a></div>
<div><a href="http://www.as.com" target="_blank" rel="noreferrer"><span>www.as.com</span></a></div>
<div><a href="http://www.livescore.com" target="_blank" rel="noreferrer"><span>www.livescore.com</span></a></div>
<div><a href="http://www.fichajes.net" target="_blank" rel="noreferrer"><span>www.fichajes.net</span></a></div>
<div><a href="http://www.futbolinterior.com.ar" target="_blank" rel="noreferrer"><span>www.futbolinterior.com.ar</span></a></div>
<br></br><h3><em>Estadísticas, información e historia</em></h3>
<div><a href="http://www.cihf.org.ar" target="_blank" rel="noreferrer"><span>www.cihf.org.ar</span></a></div>
<div><a href="http://www.footballdatabase.eu" target="_blank" rel="noreferrer"><span>www.footballdatabase.eu</span></a></div>
<div><a href="http://www.rsssf.com" target="_blank" rel="noreferrer"><span>www.rsssf.com</span></a></div>
<div><a href="http://www.wikipedia.org" target="_blank" rel="noreferrer"><span>www.wikipedia.org</span></a></div>
<div><a href="http://www.iffhs.de/" target="_blank" rel="noreferrer"><span>www.iffhs.de</span></a></div>
<div></div>
<div><a href="http://www.historiadeboca.com.ar" target="_blank" rel="noreferrer"><span>www.historiadeboca.com.ar</span></a></div>
<div><a href="http://www.efemeridesdeportivas.blogspot.com" target="_blank" rel="noreferrer"><span>www.efemeridesdeportivas.blogspot.com</span></a></div>
<div><a href="http://www.11v11.com" target="_blank" rel="noreferrer"><span>www.11v11.com</span></a></div>
<div><a href="http://www.soccer-db.info" target="_blank" rel="noreferrer"><span>www.soccer-db.info</span></a></div>
<div><a href="http://www.lapaginamillonaria.com" target="_blank" rel="noreferrer"><span>www.lapaginamillonaria.com</span></a></div>
<div><a href="http://www.arquivodosmundiais.com.br" target="_blank" rel="noreferrer"><span>www.arquivodosmundiais.com.br</span></a></div>
<div><a href="http://www.pinicio.com.ar/" target="_blank" rel="noreferrer">www.pinicio.com.ar - Pagina personalizable para poner de inicio con muchos links</a></div>
<br></br><h3><em>Blogs </em></h3>
<div><a href="http://blogcihf.blogspot.com/" target="_blank" rel="noreferrer">blogcihf.blogspot.com</a></div>
<div><a href="http://historiatemperley.blogspot.com/" target="_blank" rel="noreferrer">historiatemperley.blogspot.com</a></div>
<div><a href="http://bocavideos.blogspot.com" target="_blank" rel="noreferrer"><span>bocavideos.blogspot.com</span></a></div>
<div><a href="http://barcamexico.jimdo.com/" target="_blank" rel="noreferrer"><span>http://barcamexico.jimdo.com/</span></a></div>
<br></br><h3><em>Más de libros</em></h3>
<div><a href="http://www.librosdeportivos.com/" target="_blank" rel="noreferrer"><span>www.librosdeportivos.com</span></a></div>
<div><a href="http://www.libreriadeportivaap.mex.tl" target="_blank" rel="noreferrer"><span>www.libreriadeportivaap.mex.tl</span></a></div>
<div><a href="www.edicionesdeportivas.com.ar" target="_blank" rel="noreferrer"><span>www.edicionesdeportivas.com.ar</span></a></div>
<br></br><h3><em>Programas de Radio</em></h3>
<div><a href="http://www.bolasinmanija.com.ar" target="_blank" rel="noreferrer"><span>www.bolasinmanija.com.ar</span></a></div>
<br></br><h3><em>Fútbol de Uruguay</em></h3>
<div><a href="http://www.tenfieldigital.com.uy" target="_blank" rel="noreferrer"><span>www.tenfieldigital.com.uy</span></a></div>
<br></br><h3><em>Fútbol de Ecuador</em></h3>
<div><a href="http://www.enciclopediadeportiva.com" target="_blank" rel="noreferrer"><span>www.enciclopediadeportiva.com</span></a></div>
<br></br><h3><em>Fútbol de Peru</em></h3>
<div><a href="http://www.peru.com/futbol" target="_blank" rel="noreferrer"><span>www.peru.com/futbol</span></a></div>
<div><a href="http://www.delgol.com" target="_blank" rel="noreferrer"><span>www.delgol.com</span></a></div>
<br></br><h3><em>Fútbol de Brasil</em></h3>
<div><a href="http://www.sambafoot.co.uk" target="_blank" rel="noreferrer"><span>www.sambafoot.co.uk</span></a></div>
<div><a href="http://arquivodosmundiais.com/placar.aspx?cod=86" target="_blank" rel="noreferrer"><span>arquivodosmundiais.com/placar.aspx?cod=86</span></a></div>
<br></br><h3><em>Fútbol de Colombia</em></h3>
<div><a href="http://bestiariodelbalon.com" target="_blank" rel="noreferrer"><span>bestiariodelbalon.com</span></a></div>
<br></br><h3><em>Fútbol de Canad&aacute;</em></h3>
<div><a href="https://canadasoccer.com" target="_blank" rel="noreferrer"><span>canadasoccer.com</span></a></div>
<br></br><h3><em>Fútbol de Guatemala</em></h3>
<div><a href="https://www.facebook.com/100AnosDeFutbolGuatemala/posts/590645260982018" target="_blank" rel="noreferrer"><span>www.facebook.com/100AnosDeFutbolGuatemala/posts/590645260982018</span></a></div>
<br></br><h3><em>Fútbol de Cuba</em></h3>
<div><a href="https://futboldecuba.blogspot.mx/" target="_blank" rel="noreferrer"><span>futboldecuba.blogspot.mx/</span></a></div>
<br></br><h3><em>Fútbol de Bolivia</em></h3>
<div><a href="http://historiadelfutbolboliviano.com" target="_blank" rel="noreferrer"><span>historiadelfutbolboliviano.com</span></a></div>
<br></br><h3><em>Fútbol de Francia</em></h3>
<div><a href="http://www.sofoot.com" target="_blank" rel="noreferrer"><span>www.sofoot.com</span></a></div>
<div><a href="http://www.lequipe.fr" target="_blank" rel="noreferrer"><span>www.lequipe.fr</span></a></div>
<div><a href="http://www.lfp.fr" target="_blank" rel="noreferrer"><span>www.lfp.fr</span></a></div>
<div><a href="http://footnostalgie.free.fr" target="_blank" rel="noreferrer"><span>footnostalgie.free.fr</span></a></div>
<br></br><h3><em>Fútbol de España</em></h3>
<div><a href="http://www.sefutbol.com" target="_blank" rel="noreferrer"><span>www.sefutbol.com</span></a></div>
<br></br><h3><em>Fútbol de Dinamarca</em></h3>
<div><a href="http://www.dbu.dk" target="_blank" rel="noreferrer"><span>www.dbu.dk</span></a></div>
<br></br><h3><em>Fútbol de Alemania</em></h3>
<div><a href="http://www.fussballdaten.de" target="_blank" rel="noreferrer"><span>www.fussballdaten.de</span></a></div>
<br></br><h3><em>Fútbol de Inglaterra</em></h3>
<div><a href="http://www.soccerbase.com" target="_blank" rel="noreferrer"><span>www.soccerbase.com</span></a></div>
<br></br><h3><em>Fútbol de República Checa</em></h3>
<div><a href="http://nv.fotbal.cz" target="_blank" rel="noreferrer"><span>nv.fotbal.cz</span></a></div>
<br></br><h3><em>Fútbol de Bélgica</em></h3>
<div><a href="http://www.belgianfootball.be" target="_blank" rel="noreferrer"><span>www.belgianfootball.be</span></a></div>
<div><a href="http://wafflesparatodos.wordpress.com" target="_blank" rel="noreferrer"><span>wafflesparatodos.wordpress.com (en espa�ol)</span></a></div>
<br></br><h3><em>Fútbol de Austria</em></h3>
<div><a href="http://www.oefb.at" target="_blank" rel="noreferrer"><span>Del Austria Viena www.oefb.at</span></a></div>
<div><a href="http://www.austria-archiv.at" target="_blank" rel="noreferrer"><span>www.austria-archiv.at</span></a></div>
<br></br><h3><em>Fútbol de Rumania</em></h3>
<div><a href="http://www.romaniansoccer.ro" target="_blank" rel="noreferrer"><span>www.romaniansoccer.ro</span></a></div>
<br></br><h3><em>Fútbol de Holanda</em></h3>
<div><a href="http://www.voetbalstats.nl" target="_blank" rel="noreferrer"><span>www.voetbalstats.nl</span></a></div>
<br></br><h3><em>Fútbol de Bulgaria</em></h3>
<div><a href="http://www.visittobulgaria.com" target="_blank" rel="noreferrer"><span>www.visittobulgaria.com</span></a></div>
<br></br><h3><em>Fútbol de Hungría</em></h3>
<div><a href="http://www.magyarvalogatott.hu" target="_blank" rel="noreferrer"><span>www.magyarvalogatott.hu</span></a></div>
<br></br><h3><em>Federación de Polonia</em></h3>
<div><a href="http://www.pzpn.pl" target="_blank" rel="noreferrer"><span>www.pzpn.pl</span></a></div>
<br></br><h3><em>Federación de Escocia</em></h3>
<div><a href="http://www.scottishfa.co.uk" target="_blank" rel="noreferrer"><span>www.scottishfa.co.uk</span></a></div>
<br></br><h3><em>Selección de Irlanda</em></h3>
<div><a href="http://www.soccerscene.ie" target="_blank" rel="noreferrer"><span>www.soccerscene.ie</span></a></div>
<br></br><h3><em>Selección de Nueva Zelanda</em></h3>
<div><a href="http://www.ultimatenzsoccer.com" target="_blank" rel="noreferrer"><span>www.ultimatenzsoccer.com</span></a></div>
<br></br><h3><em>Fútbol de Argelia</em></h3>
<div><a href="http://www.dzfoot.com" target="_blank" rel="noreferrer"><span>www.dzfoot.com</span></a></div>
<br></br><h3><em>Fútbol de El Salvador</em></h3>
<div><a href="http://www.elbaloncuscatleco.com" target="_blank" rel="noreferrer"><span>www.elbaloncuscatleco.com</span></a></div>
<br></br><h3><em>Fútbol de Estados Unidos</em></h3>
<div><a href="http://www.mlssoccer.com" target="_blank" rel="noreferrer"><span>www.mlssoccer.com</span></a></div>
<br></br><h3><em>Fútbol de Mar del Plata</em></h3>
<div><a href="http://historiadelfutbolmarplatense.blogspot.com" target="_blank" rel="noreferrer"><span>historiadelfutbolmarplatense.blogspot.com</span></a></div>
<br></br><h3><em>Mundiales</em></h3>
<div><a href="http://www.planetworldcup.com" target="_blank" rel="noreferrer"><span>www.planetworldcup.com</span></a></div>
<div><a href="http://allworldcup.narod.ru" target="_blank" rel="noreferrer"><span>allworldcup.narod.ru</span></a></div>
<br></br><h3><em>Fútbol de Australia</em></h3>
<div><a href="http://www.ozfootball.net" target="_blank" rel="noreferrer"><span>www.ozfootball.net</span></a></div>
<br></br><h3><em>Federación de Futbol de Eslovenia</em></h3>
<div><a href="http://www.nzs.si" target="_blank" rel="noreferrer"><span>www.nzs.si</span></a></div>
<br></br><h3><em>Diario de Costa Rica</em></h3>
<div><a href="http://www.nacion.com" target="_blank" rel="noreferrer"><span>www.nacion.com</span></a></div>
<br></br><h3><em>Selección de Futbol de Jamaica</em></h3>
<div><a href="http://www.reggaeboyzsc.com" target="_blank" rel="noreferrer"><span>www.reggaeboyzsc.com</span></a></div>
<br></br><h3><em>Selección de Futbol de Trinidad y Tobago</em></h3>
<div><a href="http://www.socawarriors.net" target="_blank" rel="noreferrer"><span>www.socawarriors.net</span></a></div>
<br></br><h3><em>Selección de Futbol de Ir&aacute;n</em></h3>
<div><a href="http://www.teammelli.com" target="_blank" rel="noreferrer"><span>www.teammelli.com</span></a></div>
<br></br><h3><em>De Estadios</em></h3>
<div><a href="http://www.worldstadiums.com" target="_blank" rel="noreferrer"><span>www.worldstadiums.com</span></a></div>
<br></br><h3><em>Clásicos</em></h3>
<div><a href="http://www.footballderbies.com" target="_blank" rel="noreferrer"><span>www.footballderbies.com</span></a></div>
<br></br><h3><em>Para seguir partidos en vivo</em></h3>
<div><a href="http://www.livescore.com" target="_blank" rel="noreferrer"><span>www.livescore.com</span></a></div>
<div><a href="http://www.flashscore.com" target="_blank" rel="noreferrer"><span>www.flashscore.com</span></a></div>
<br></br><h3><em>Temas de interés general</em></h3>
<div><a href="http://www.la-expresion.com.mx" target="_blank" rel="noreferrer"><span>www.la-expresion.com.mx</span></a></div>

      </div>
    </div>
    </section>
  )
  case "english":
    return (
      <section className='links'>
        <div className='container'>
            <div className='links-text'>
            <h2 className='links-title fs-26 ls-1'>USEFUL LINKS / FRIEND'S WEBPAGES:</h2>
<h3><em>Official Pages</em></h3>
<div><a href="http://www.fifa.com" target="_blank" rel="noreferrer"><span>www.fifa.com</span></a></div>
<div><a href="http://www.uefa.com" target="_blank" rel="noreferrer"><span>www.uefa.com</span></a></div>
<div><a href="http://www.conmebol.com" target="_blank" rel="noreferrer"><span>www.conmebol.com</span></a></div>
<div><a href="http://www.concacaf.com" target="_blank" rel="noreferrer"><span>www.concacaf.com</span></a></div>
<div><a href="http://www.cafonline.com" target="_blank" rel="noreferrer"><span>www.cafonline.com</span></a></div>
<div><a href="http://www.the-afc.com" target="_blank" rel="noreferrer"><span>www.the-afc.com</span></a></div>
<div><a href="http://www.oceaniafootball.com" target="_blank" rel="noreferrer"><span>www.oceaniafootball.com</span></a></div>
<div><a href="http://www.femexfut.org.mx" target="_blank" rel="noreferrer"><span><strong>www.femexfut.org.mx</strong></span></a></div>
<div><a href="http://www.salvemosalfutbol.org" target="_blank" rel="noreferrer"><span>www.salvemosalfutbol.org</span></a></div>
<div><a href="http://catalogo.iib.unam.mx/F/792ITDY8T46TS76JA119H6472URNAMIY6TTVLTTBKVJX497HL2-28637?func=find-b&request=Futbol&x=40&y=7&find_code=WRD&adjacent=N&filter_code_2=WYR&filter_request_2=&filter_code_3=WYR&filter_request_3=&filter_code_1=WLN&filter_request_1=&filter_code_4=WFM&filter_request_4=" target="_blank" rel="noreferrer"><span id="lw_1248213604_6">Cat&aacute;logo de Libros de la UNAM</span></a></div>
<br></br><h3><em>Football Magazines, Newspapers and Channels</em></h3>
<div><a href="http://www.mediotiempo.com" target="_blank" rel="noreferrer"><span>www.mediotiempo.com</span></a></div>
<div><a href="http://www.goal.com" target="_blank" rel="noreferrer"><span>www.goal.com</span></a></div>
<div><a href="http://www.espn.com/" target="_blank" rel="noreferrer"><span>www.espn.com</span></a></div>
<div><a href="http://www.ole.com.ar" target="_blank" rel="noreferrer"><span>www.ole.com.ar</span></a></div>
<div><a href="http://www.marca.com" target="_blank" rel="noreferrer"><span>www.marca.com</span></a></div>
<div><a href="http://www.as.com" target="_blank" rel="noreferrer"><span>www.as.com</span></a></div>
<div><a href="http://www.livescore.com" target="_blank" rel="noreferrer"><span>www.livescore.com</span></a></div>
<div><a href="http://www.fichajes.net" target="_blank" rel="noreferrer"><span>www.fichajes.net</span></a></div>
<div><a href="http://www.futbolinterior.com.ar" target="_blank" rel="noreferrer"><span>www.futbolinterior.com.ar</span></a></div>
<br></br><h3><em>Statistics, info and history</em></h3>
<div><a href="http://www.cihf.org.ar" target="_blank" rel="noreferrer"><span>www.cihf.org.ar</span></a></div>
<div><a href="http://www.footballdatabase.eu" target="_blank" rel="noreferrer"><span>www.footballdatabase.eu</span></a></div>
<div><a href="http://www.rsssf.com" target="_blank" rel="noreferrer"><span>www.rsssf.com</span></a></div>
<div><a href="http://www.wikipedia.org" target="_blank" rel="noreferrer"><span>www.wikipedia.org</span></a></div>
<div><a href="http://www.iffhs.de/" target="_blank" rel="noreferrer"><span>www.iffhs.de</span></a></div>
<div></div>
<div><a href="http://www.historiadeboca.com.ar" target="_blank" rel="noreferrer"><span>www.historiadeboca.com.ar</span></a></div>
<div><a href="http://www.efemeridesdeportivas.blogspot.com" target="_blank" rel="noreferrer"><span>www.efemeridesdeportivas.blogspot.com</span></a></div>
<div><a href="http://www.11v11.com" target="_blank" rel="noreferrer"><span>www.11v11.com</span></a></div>
<div><a href="http://www.soccer-db.info" target="_blank" rel="noreferrer"><span>www.soccer-db.info</span></a></div>
<div><a href="http://www.lapaginamillonaria.com" target="_blank" rel="noreferrer"><span>www.lapaginamillonaria.com</span></a></div>
<div><a href="http://www.arquivodosmundiais.com.br" target="_blank" rel="noreferrer"><span>www.arquivodosmundiais.com.br</span></a></div>
<div><a href="http://www.pinicio.com.ar/" target="_blank" rel="noreferrer">www.pinicio.com.ar - Pagina personalizable para poner de inicio con muchos links</a></div>
<br></br><h3><em>Blogs </em></h3>
<div><a href="http://blogcihf.blogspot.com/" target="_blank" rel="noreferrer">blogcihf.blogspot.com</a></div>
<div><a href="http://historiatemperley.blogspot.com/" target="_blank" rel="noreferrer">historiatemperley.blogspot.com</a></div>
<div><a href="http://bocavideos.blogspot.com" target="_blank" rel="noreferrer"><span>bocavideos.blogspot.com</span></a></div>
<div><a href="http://barcamexico.jimdo.com/" target="_blank" rel="noreferrer"><span>http://barcamexico.jimdo.com/</span></a></div>
<br></br><h3><em>More about books </em></h3>
<div><a href="http://www.librosdeportivos.com/" target="_blank" rel="noreferrer"><span>www.librosdeportivos.com</span></a></div>
<div><a href="http://www.libreriadeportivaap.mex.tl" target="_blank" rel="noreferrer"><span>www.libreriadeportivaap.mex.tl</span></a></div>
<div><a href="www.edicionesdeportivas.com.ar" target="_blank" rel="noreferrer"><span>www.edicionesdeportivas.com.ar</span></a></div>
<br></br><h3><em>Radio Shows</em></h3>
<div><a href="http://www.bolasinmanija.com.ar" target="_blank" rel="noreferrer"><span>www.bolasinmanija.com.ar</span></a></div>
<br></br><h3><em>Soccer from Uruguay</em></h3>
<div><a href="http://www.tenfieldigital.com.uy" target="_blank" rel="noreferrer"><span>www.tenfieldigital.com.uy</span></a></div>
<br></br><h3><em>Soccer from Ecuador</em></h3>
<div><a href="http://www.enciclopediadeportiva.com" target="_blank" rel="noreferrer"><span>www.enciclopediadeportiva.com</span></a></div>
<br></br><h3><em>Soccer from Peru</em></h3>
  <div><a href="http://www.peru.com/futbol" target="_blank" rel="noreferrer"><span>www.peru.com/futbol</span></a></div>
  <div><a href="http://www.delgol.com" target="_blank" rel="noreferrer"><span>www.delgol.com</span></a></div>
  <br></br><h3><em>Soccer from Brasil</em></h3>
  <div><a href="http://www.sambafoot.co.uk" target="_blank" rel="noreferrer"><span>www.sambafoot.co.uk</span></a></div>
  <div><a href="http://arquivodosmundiais.com/placar.aspx?cod=86" target="_blank" rel="noreferrer"><span>arquivodosmundiais.com/placar.aspx?cod=86</span></a></div>
  <br></br><h3><em>Soccer from Colombia</em></h3>
  <div><a href="http://bestiariodelbalon.com" target="_blank" rel="noreferrer"><span>bestiariodelbalon.com</span></a></div>
  <br></br><h3><em>Soccer from Canada</em></h3>
  <div><a href="https://canadasoccer.com" target="_blank" rel="noreferrer"><span>canadasoccer.com</span></a></div>
  <br></br><h3><em>Soccer from Guatemala</em></h3>
  <div><a href="https://www.facebook.com/100AnosDeFutbolGuatemala/posts/590645260982018" target="_blank" rel="noreferrer"><span>www.facebook.com/100AnosDeFutbolGuatemala/posts/590645260982018</span></a></div>
  <br></br><h3><em>Soccer from Cuba</em></h3>
  <div><a href="https://futboldecuba.blogspot.mx/" target="_blank" rel="noreferrer"><span>futboldecuba.blogspot.mx/</span></a></div>
  <br></br><h3><em>Soccer from Bolivia</em></h3>
  <div><a href="http://historiadelfutbolboliviano.com" target="_blank" rel="noreferrer"><span>historiadelfutbolboliviano.com</span></a></div>
  <br></br><h3><em>Soccer from France</em></h3>
  <div><a href="http://www.sofoot.com" target="_blank" rel="noreferrer"><span>www.sofoot.com</span></a></div>
  <div><a href="http://www.lequipe.fr" target="_blank" rel="noreferrer"><span>www.lequipe.fr</span></a></div>
  <div><a href="http://www.lfp.fr" target="_blank" rel="noreferrer"><span>www.lfp.fr</span></a></div>
  <div><a href="http://footnostalgie.free.fr" target="_blank" rel="noreferrer"><span>footnostalgie.free.fr</span></a></div>
  <br></br><h3><em>Soccer from Spain</em></h3>
  <div><a href="http://www.sefutbol.com" target="_blank" rel="noreferrer"><span>www.sefutbol.com</span></a></div>
  <br></br><h3><em>Soccer from Denmark</em></h3>
  <div><a href="http://www.dbu.dk" target="_blank" rel="noreferrer"><span>www.dbu.dk</span></a></div>
  <br></br><h3><em>Soccer from Germany</em></h3>
  <div><a href="http://www.fussballdaten.de" target="_blank" rel="noreferrer"><span>www.fussballdaten.de</span></a></div>
  <br></br><h3><em>Soccer from England</em></h3>
  <div><a href="http://www.soccerbase.com" target="_blank" rel="noreferrer"><span>www.soccerbase.com</span></a></div>
  <br></br><h3><em>Soccer from Czech Republic</em></h3>
  <div><a href="http://nv.fotbal.cz" target="_blank" rel="noreferrer"><span>nv.fotbal.cz</span></a></div>
  <br></br><h3><em>Soccer from Belgium</em></h3>
  <div><a href="http://www.belgianfootball.be" target="_blank" rel="noreferrer"><span>www.belgianfootball.be</span></a></div>
  <div><a href="http://wafflesparatodos.wordpress.com" target="_blank" rel="noreferrer"><span>wafflesparatodos.wordpress.com (en espa�ol)</span></a></div>
  <br></br><h3><em>Soccer from Austria</em></h3>
  <div><a href="http://www.oefb.at" target="_blank" rel="noreferrer"><span>Del Austria Viena www.oefb.at</span></a></div>
  <div><a href="http://www.austria-archiv.at" target="_blank" rel="noreferrer"><span>www.austria-archiv.at</span></a></div>
  <br></br><h3><em>Soccer from Romania</em></h3>
  <div><a href="http://www.romaniansoccer.ro" target="_blank" rel="noreferrer"><span>www.romaniansoccer.ro</span></a></div>
  <br></br><h3><em>Soccer from Neatherlands</em></h3>
  <div><a href="http://www.voetbalstats.nl" target="_blank" rel="noreferrer"><span>www.voetbalstats.nl</span></a></div>
  <br></br><h3><em>Soccer from Bulgaria</em></h3>
  <div><a href="http://www.visittobulgaria.com" target="_blank" rel="noreferrer"><span>www.visittobulgaria.com</span></a></div>
  <br></br><h3><em>Soccer from Hungary</em></h3>
  <div><a href="http://www.magyarvalogatott.hu" target="_blank" rel="noreferrer"><span>www.magyarvalogatott.hu</span></a></div>
  <br></br><h3><em>Federation of Poland</em></h3>
  <div><a href="http://www.pzpn.pl" target="_blank" rel="noreferrer"><span>www.pzpn.pl</span></a></div>
  <br></br><h3><em>Federation of Scotland</em></h3>
  <div><a href="http://www.scottishfa.co.uk" target="_blank" rel="noreferrer"><span>www.scottishfa.co.uk</span></a></div>
  <br></br><h3><em>Ireland National Team</em></h3>
  <div><a href="http://www.soccerscene.ie" target="_blank" rel="noreferrer"><span>www.soccerscene.ie</span></a></div>
  <br></br><h3><em>New Zeland National Team</em></h3>
  <div><a href="http://www.ultimatenzsoccer.com" target="_blank" rel="noreferrer"><span>www.ultimatenzsoccer.com</span></a></div>
  <br></br><h3><em>Soccer from Argelia</em></h3>
  <div><a href="http://www.dzfoot.com" target="_blank" rel="noreferrer"><span>www.dzfoot.com</span></a></div>
  <br></br><h3><em>Soccer from El Salvador</em></h3>
  <div><a href="http://www.elbaloncuscatleco.com" target="_blank" rel="noreferrer"><span>www.elbaloncuscatleco.com</span></a></div>
  <br></br><h3><em>Soccer from United States</em></h3>
  <div><a href="http://www.mlssoccer.com" target="_blank" rel="noreferrer"><span>www.mlssoccer.com</span></a></div>
  <br></br><h3><em>Soccer from Mar del Plata</em></h3>
  <div><a href="http://historiadelfutbolmarplatense.blogspot.com" target="_blank" rel="noreferrer"><span>historiadelfutbolmarplatense.blogspot.com</span></a></div>
  <br></br><h3><em>World Cups</em></h3>
  <div><a href="http://www.planetworldcup.com" target="_blank" rel="noreferrer"><span>www.planetworldcup.com</span></a></div>
  <div><a href="http://allworldcup.narod.ru" target="_blank" rel="noreferrer"><span>allworldcup.narod.ru</span></a></div>
  <br></br><h3><em>Soccer from Australia</em></h3>
  <div><a href="http://www.ozfootball.net" target="_blank" rel="noreferrer"><span>www.ozfootball.net</span></a></div>
  <br></br><h3><em>Federación de Futbol de Eslovenia</em></h3>
  <div><a href="http://www.nzs.si" target="_blank" rel="noreferrer"><span>www.nzs.si</span></a></div>
  <br></br><h3><em>Costa Rica Newspaper</em></h3>
  <div><a href="http://www.nacion.com" target="_blank" rel="noreferrer"><span>www.nacion.com</span></a></div>
  <br></br><h3><em>Jamaica National Team</em></h3>
  <div><a href="http://www.reggaeboyzsc.com" target="_blank" rel="noreferrer"><span>www.reggaeboyzsc.com</span></a></div>
  <br></br><h3><em>Trinidad y Tobago National Team</em></h3>
  <div><a href="http://www.socawarriors.net" target="_blank" rel="noreferrer"><span>www.socawarriors.net</span></a></div>
  <br></br><h3><em>Iran National Team</em></h3>
  <div><a href="http://www.teammelli.com" target="_blank" rel="noreferrer"><span>www.teammelli.com</span></a></div>
  <br></br><h3><em>Stadiums</em></h3>
  <div><a href="http://www.worldstadiums.com" target="_blank" rel="noreferrer"><span>www.worldstadiums.com</span></a></div>
  <br></br><h3><em>Classics matches</em></h3>
  <div><a href="http://www.footballderbies.com" target="_blank" rel="noreferrer"><span>www.footballderbies.com</span></a></div>
  <br></br><h3><em>Following matches live</em></h3>
  <div><a href="http://www.livescore.com" target="_blank" rel="noreferrer"><span>www.livescore.com</span></a></div>
  <div><a href="http://www.flashscore.com" target="_blank" rel="noreferrer"><span>www.flashscore.com</span></a></div>
  <br></br><h3><em>General interest topics</em></h3>
  <div><a href="http://www.la-expresion.com.mx" target="_blank" rel="noreferrer"><span>www.la-expresion.com.mx</span></a></div>
  
        </div>
      </div>
      </section>
    )
  default:
    break;
}
}

export default Links