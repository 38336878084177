import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BookContext } from '../../context/books';
import "./Inicio.css";

const Inicio = () => {
  const { idioma } = useContext(BookContext);
  switch (idioma) {
    case "español":
  return (
    <section className='inicio'>
      <div className='container'>
          <div className='inicio-text'>
            <h2 className='inicio-title fs-26 ls-1'>COMPRA-VENTA DE MATERIAL</h2>
<p>Aquí encontrarás todo tipo de material deportivo y no deportivo a la venta. Pueden ser libros, revistas, diarios, suplementos, álbumes, fotos, CD's, DVD's, pins, playeras, gorras, etc.</p>
<p>Compro y vendo libros y revistas de futbol. Interesados, escribir a: <a href="materialdefutbol@yahoo.com.mx">materialdefutbol@yahoo.com.mx</a></p>
<p className='fs-18'><strong>Click en cada item para más información!!</strong></p>
<p>&nbsp;</p>
<ul>
  <li><h1>Revistas Futbol Colección de Oro: Son 80 ejemplares discontinuados de los 60 y 70.</h1>
      <blockquote>
      <Link to = {`/venta/id/857126a3-bf15-416f-9b44-1c4e3235bc82`}><p>Revistas Futbol Colección de Oro</p></Link>
      <p>&nbsp;</p>
    </blockquote>
  </li>
  <li><h1>Revistas de México de Deporte en General</h1>
    <blockquote>
    <Link to = {`/venta/id/6567d062-d9ce-40d6-a86d-337a5d48b4a5`}><p><>Magazine Deportivo</></p></Link>
    <Link to = {`/venta/id/d9d1648b-a8f7-405a-b95f-a6377cdf20cb`}><p><>Revista Deporte Ilustrado</></p></Link>
    <Link to = {`/venta/id/e7a6232f-de7f-4272-a368-bc4ab3f0da50`}><p><>Revista Deporte Ilustrado Edición Especial</></p></Link>
      <p>&nbsp;</p>
    </blockquote>
	</li>
  <li><h1>Revistas de México de Futbol</h1>
    <blockquote>
    <Link to = {`/venta/id/a6bdfc6d-f548-425e-a129-cf5f31b35c0c`}><p><>Fascículos EL Libro del Futbol</></p></Link>
    <Link to = {`/venta/id/99f71659-70d9-4e0c-bdaa-3d7b7d18d9cf`}><p><>Revista All Futbol de las Américas</></p></Link>
    <Link to = {`/venta/id/9f70164f-f776-4055-be45-446ba0569012`}><p><>Revista Contienda Futbolera</></p></Link>
    <Link to = {`/venta/id/2d8bc9f0-f401-4e48-8281-26b69994f5cb`}><p><>Revistas Oficial FMF Futbol</></p></Link>
    <Link to = {`/venta/id/94b15c14-8cac-4a97-84db-db985dfe5ab7`}><p><>Revista Santos Laguna</></p></Link>
    <Link to = {`/venta/id/de3b5963-b282-4e89-a8dc-64593fa0eeaf`}><p><>Revista Soccer Internacional</></p></Link>
    <Link to = {`/venta/id/0178df63-cfca-4cc2-8ba6-07115e8e634e`}><p><>Revista Sólo Mexicanos</></p></Link>
    <Link to = {`/venta/id/049d22c1-8359-4598-a798-547870d9492d`}><p><>Revista Corresponsal Tuzo</></p></Link>
    <Link to = {`/venta/id/857126a3-bf15-416f-9b44-1c4e3235bc82`}><p><>Revistas Futbol Colección de Oro</></p></Link>
    </blockquote>
  </li>
</ul>

          </div>
      </div>
    </section>
  );
  case "english":
    return (
      <section className='inicio'>
        <div className='container'>
            <div className='inicio-text'>
              <h2 className='inicio-title fs-26 ls-1'>BUY-SELL STUFF</h2>
  <p>Here you'll find all kind of sport and non-sport stuff for selling. It can be books, magazines, newspapers, suplements, albums, photos, CD's, DVD's, pins, shirts, caps, etc.</p>
  <p>I buy and sell soccer books and magazines. Those concerned, write to: <a href="materialdefutbol@yahoo.com.mx">materialdefutbol@yahoo.com.mx</a></p>
  <p className='fs-18'><strong>Click on each item for more info!!</strong></p>
  <p>&nbsp;</p>

  <ul>
    <li><h1>Magazines Futbol Coleccion de Oro: We got 80 discontinued editions from 60's and 70's.</h1>
        <blockquote>
        <Link to = {`/venta/id/857126a3-bf15-416f-9b44-1c4e3235bc82`}><p>Magazines Futbol Coleccion de Oro</p></Link>
        <p>&nbsp;</p>
      </blockquote>
    </li>
    <li><h1>Mexico's General Sport Magazines</h1>
      <blockquote>
      <Link to = {`/venta/id/6567d062-d9ce-40d6-a86d-337a5d48b4a5`}><p><>Magazine Magazine Deportivo</></p></Link>
      <Link to = {`/venta/id/d9d1648b-a8f7-405a-b95f-a6377cdf20cb`}><p><>Magazine Deporte Ilustrado</></p></Link>
      <Link to = {`/venta/id/e7a6232f-de7f-4272-a368-bc4ab3f0da50`}><p><>Magazine Deporte Ilustrado Edicion Especial</></p></Link>
        <p>&nbsp;</p>
      </blockquote>
    </li>
    <li><h1>Mexico's Soccer Magazines</h1>
      <blockquote>
      <Link to = {`/venta/id/a6bdfc6d-f548-425e-a129-cf5f31b35c0c`}><p><>Bundles EL Libro del Futbol</></p></Link>
      <Link to = {`/venta/id/99f71659-70d9-4e0c-bdaa-3d7b7d18d9cf`}><p><>Magazine All Futbol de las Américas</></p></Link>
      <Link to = {`/venta/id/9f70164f-f776-4055-be45-446ba0569012`}><p><>Magazine Contienda Futbolera</></p></Link>
      <Link to = {`/venta/id/2d8bc9f0-f401-4e48-8281-26b69994f5cb`}><p><>Official FMF Soccer Magazine</></p></Link>
      <Link to = {`/venta/id/94b15c14-8cac-4a97-84db-db985dfe5ab7`}><p><>Magazine Santos Laguna</></p></Link>
      <Link to = {`/venta/id/de3b5963-b282-4e89-a8dc-64593fa0eeaf`}><p><>Magazine Soccer Internacional</></p></Link>
      <Link to = {`/venta/id/0178df63-cfca-4cc2-8ba6-07115e8e634e`}><p><>Magazine Sólo Mexicanos</></p></Link>
      <Link to = {`/venta/id/049d22c1-8359-4598-a798-547870d9492d`}><p><>Magazine Corresponsal Tuzo</></p></Link>
      <Link to = {`/venta/id/857126a3-bf15-416f-9b44-1c4e3235bc82`}><p><>Magazines Futbol Colección de Oro</></p></Link>
      </blockquote>
    </li>
  </ul>
  
            </div>
        </div>
      </section>
    );
  default:
    break;
  }
}

export default Inicio