import React, { useState, useContext } from 'react';
import { BookContext } from '../../context/books';
import { generateClient } from "aws-amplify/api";
import { getCurrentUser } from "aws-amplify/auth";
import { sendMailC } from '../../api/mutations';
import "./Contact.css";

const client = generateClient();

const Contact = () => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [messag, setMessag] = useState('')
  const [subject, setSubject] = useState('')
  const { idioma } = useContext(BookContext);
  const endpoint = 'https://TBD...LambdaURL...TBD' // Add this later
  
  async function authenticated() {
    try {
        let { username } = await getCurrentUser();
          if (username) return true;
    } catch {
        return false;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const payload = { username: name, usermail: email, messag: messag, subject: subject }

    try {
    let isLoggedIn = await authenticated();
    const { data } = await client.graphql({
      query: sendMailC,
      variables: { input: payload },
      authMode: isLoggedIn ? "userPool" : "iam" 
    })
    console.log (data); // Cambiar por mensaje de enviado correcto
    } catch (err) {
        console.log(err); //agregar que hubo error
        }
    }

  switch (idioma) {
    case "español":
  return (
    <section className='contact-form'>
      <div className='containerct'>
          <div className='about-text'>
            <h2 className='about-title fs-26 ls-1'>CONTACTO</h2>
          </div>
              <form className="contact-form" action={endpoint} onSubmit={handleSubmit} method="POST">
                <div className='contact-form-elem'>
                  <input className="form-control" type="text" placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)} /><br />
                </div>
                <div className='contact-form-elem'>
                  <input className="form-control" type="email" placeholder="Correo Electrónico" value={email} onChange={(e) => setEmail(e.target.value)} /><br />
                </div>
                <div className='contact-form-elem'>
                  <input className="form-control" type="text" placeholder="Asunto" value={subject} onChange={(e) => setSubject(e.target.value)} /><br />
                </div>
                <div className='contact-form-elem'>
                  <textarea className="message-control" placeholder="Mensaje" value={messag} onChange={(e) => setMessag(e.target.value)} /><br />
                </div>
                  <button className="contact-form-button" type="submit">Enviar mensaje</button>
              </form>
      </div>
    </section>
  )
  case "english":
    return (
      <section className='contact-form'>
        <div className='containerct'>
            <div className='about-text'>
              <h2 className='about-title fs-26 ls-1'>CONTACT</h2>
            </div>
                <form className="contact-form" action={endpoint} onSubmit={handleSubmit} method="POST">
                  <div className='contact-form-elem'>
                    <input className="form-control" type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} /><br />
                  </div>
                  <div className='contact-form-elem'>
                    <input className="form-control" type="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} /><br />
                  </div>
                  <div className='contact-form-elem'>
                    <input className="form-control" type="text" placeholder="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} /><br />
                  </div>
                  <div className='contact-form-elem'>
                    <textarea className="message-control" placeholder="Message" value={messag} onChange={(e) => setMessag(e.target.value)} /><br />
                  </div>
                    <button className="contact-form-button" type="submit">Send message</button>
                </form>
        </div>
      </section>
    )
  default:
  break;
}
}

export default Contact