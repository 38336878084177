import React, {useRef, useEffect, useContext } from 'react';
import { FaSearch } from 'react-icons/fa';
//import { useNavigate } from 'react-router-dom';
import { BookContext } from "../../../../../context/books";
import EditarLibro from '../../editLibs/editLibs';
import "./SearchFormB.css";

const SearchFormB = ({tablaB}) => {
    const {setSearchTerm, setSearchTermRev, setResultTitle} = useContext(BookContext);
    const searchText = useRef('');

    useEffect(() => searchText.current.focus(), []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let tempSearchTerm = searchText.current.value.trim();
        if((tempSearchTerm.replace(/[^\w\s]/gi,"")).length === 0){
            setSearchTerm("El librooo");
            setResultTitle("Agregue algo...");
        } else {
            if (tablaB === "libros") {
            setSearchTerm(searchText.current.value); 
            } else {setSearchTermRev (searchText.current.value)};
        }
        return <EditarLibro />
    }

    return (
        <div className='search-form'>
            <div className='containersf'>
                <div className='search-form-content'>
                    <form className='search-form' onSubmit={handleSubmit}>
                        <div className='search-form-elem flex flex-sb bg-white'>
                            <input type = "text" className='form-control' placeholder='Escriba aqui el texto a buscar...' ref = {searchText} />
                            <button type = "submit" className='flex flex-c' onClick={handleSubmit}>
                                <FaSearch className='text-green' size = {16} />
                            </button>
                        </div> 
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SearchFormB;