import React, { useContext } from "react";
import SearchFormB from './SearchFormB/SearchFormB';
import { BookContext } from "../../../../context/books";
import './buscEdit.css';


const BuscEdit = ({tablaB}) => {
//    const [criterio, setCriterio] = useState({criterio: "bookname"});
    const {criterio, setCriterio } = useContext(BookContext);

    return (
        <nav className='buscador' id = "buscador">
            <div className="buscador-subtitle">
                { tablaB === "libros" ? <span>Buscador Libros</span> : <span>Buscador Revistas</span> }
            </div>
            <div className="buscador-collapse">
                <ul className="buscador-nav">
                    <li className="us-form center">
                        Buscar por:
                            <select
                                name="criterio"
                                type="criterio"
                                placeholder={ tablaB === "libros" ? "Nombre del libro" : "Nombre de la revista"}
                                value={criterio}
                                onChange={(e) => setCriterio(e.target.value)}
                                required
                            >
                                { tablaB === "libros" ? <option value="bookname">Nombre del libro</option> : <option value="bookname">Nombre de la revista</option> } 
                                <option value="authorname">Nombre del autor</option>
                            </select>
                        
                    </li>
                    <li>
                        <SearchFormB tablaB={tablaB} />
                    </li>
                </ul>
         </div>
        </nav>
    )
}

export default BuscEdit;