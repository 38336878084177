import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../Loader/Loader";
import coverImg from "../../images/cover_not_found.jpg";
import "./RevistasDetails.css";
import {FaArrowLeft} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import config from '../../aws-exports';
import { BookContext } from '../../context/books';

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

const RevistasDetails = () => {
    const {idDet } = useParams();
    const [loading, setLoading] = useState(false);
    const [revista, setRevista] = useState(null);
    const navigate = useNavigate();
    const { getRevista, idioma } = useContext(BookContext);

    useEffect(() => {
        setLoading(true);

        function formarFecha (dia, mes) {
            let mesEscrito = "";
            switch(idioma) {
                case "español":
                switch (mes) {
                    case 1: mesEscrito = "Enero"; break;
                    case 2: mesEscrito = "Febrero"; break;
                    case 3: mesEscrito = "Marzo"; break;
                    case 4: mesEscrito = "Abril"; break;
                    case 5: mesEscrito = "Mayo"; break;
                    case 6: mesEscrito = "Junio"; break;
                    case 7: mesEscrito = "Julio"; break;
                    case 8: mesEscrito = "Agosto"; break;
                    case 9: mesEscrito = "Septiembre"; break;
                    case 10: mesEscrito = "Octubre"; break;
                    case 11: mesEscrito = "Noviembre"; break;
                    case 12: mesEscrito = "Diciembre"; break;
                    default: break;
                }
                if (dia === 0) {return mesEscrito} 
                    else { return dia + " de " + mesEscrito}
                case "english":
                    switch (mes) {
                        case 1: mesEscrito = "January"; break;
                        case 2: mesEscrito = "February"; break;
                        case 3: mesEscrito = "March"; break;
                        case 4: mesEscrito = "April"; break;
                        case 5: mesEscrito = "May"; break;
                        case 6: mesEscrito = "June"; break;
                        case 7: mesEscrito = "July"; break;
                        case 8: mesEscrito = "August"; break;
                        case 9: mesEscrito = "September"; break;
                        case 10: mesEscrito = "October"; break;
                        case 11: mesEscrito = "November"; break;
                        case 12: mesEscrito = "December"; break;
                        default: break;
                    }
                    if (dia === 0) {return mesEscrito} 
                        else { return mesEscrito + " " + dia}
                default:
                break;
            }
        }

        async function getRevistaDetails(){
            try{
                const data = await getRevista(idDet);
                if (data){
                    const {titulo, autor, portada, diaedicion, mesedicion, anoedicion} = data;
                    const newGuia = {
                        titulo: titulo,
                        autor: autor,
                        cover_img: portada ? `https://${bucket}.s3.${region}.amazonaws.com/imgrevistas/${portada}`: coverImg,
                        diaymes: formarFecha(diaedicion, mesedicion),
                        anoedicion: anoedicion
                    };
                    setRevista(newGuia);
                } else {
                    setRevista(null);
                }
                setLoading(false);
            } catch(error){
                console.log(error);
                setLoading(false);
            }
        }
        getRevistaDetails();
    }, [getRevista, idDet, idioma]);

    if(loading) return <Loading />;
    switch (idioma) {
      case "español":
    return (
        <section className='revistas-details'>
            <div className="container">
                <button type='button' className="flex flex-c back-btn" onClick={() => { setRevista(null); navigate("/revista") } }>
                    <FaArrowLeft size = {22} />
                    <span className="fs-18 fw-6">Atrás</span>
                </button>

                <div className='revistas-details-content grid'>
                    <div className='revistas-details-img'>
                        <img src = {revista?.cover_img} alt = "cover img" />
                    </div>
                    <div className='revistas-details-info'>
                        <div className='revistas-details-item title'>
                            <span className='fw-6 fs-24'>{revista?.titulo}</span>
                        </div>
                        <div className='revistas-details-item'>
                            <span className='fw-6'>Autor: </span>
                           <span className='text-italic'>{revista?.autor}</span>
                        </div>
                        <div className='revistas-details-item'>
                            <span className='fw-6'>Fecha de edición: </span>
                           <span className='text-italic'>{revista?.diaymes + " de " + revista?.anoedicion}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    case "english":
        return (
            <section className='revistas-details'>
                <div className="container">
                    <button type='button' className="flex flex-c back-btn" onClick={() => { setRevista(null); navigate("/revista") } }>
                        <FaArrowLeft size = {22} />
                        <span className="fs-18 fw-6">Back</span>
                    </button>
    
                    <div className='revistas-details-content grid'>
                        <div className='revistas-details-img'>
                            <img src = {revista?.cover_img} alt = "cover img" />
                        </div>
                        <div className='revistas-details-info'>
                            <div className='revistas-details-item title'>
                                <span className='fw-6 fs-24'>{revista?.titulo}</span>
                            </div>
                            <div className='revistas-details-item'>
                                <span className='fw-6'>Author: </span>
                               <span className='text-italic'>{revista?.autor}</span>
                            </div>
                            <div className='revistas-details-item'>
                                <span className='fw-6'>Edition's date: </span>
                               <span className='text-italic'>{revista?.diaymes + ", " + revista?.anoedicion}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
        default:
         break;
    }
}

export default RevistasDetails;