import React, { useContext } from 'react';
import { BookContext } from '../../context/books';
import "./MXTambien.css";

const MXTambien = () => {
  const { idioma } = useContext(BookContext);
  switch (idioma) {
    case "español":
  return (
    <section className='about'>
      <div className='container'>
          <div className='about-text'>
            <h2 className='about-title fs-26 ls-1'>Estante México - Y Tambien existen...</h2>
           <p>Los siguientes libros se han publicado, aunque todavía no hemos podido dar con ellos como para subirlos a la página. Si alguien desea incorporar a esta lista una edición faltante, favor de contactarse.</p>
            <div className="vinul"> 
              <ul>
                <li>
                  <p className='fw-6 fs-15'>Historia del Toluca</p>
                  <h5 className='fw-5 fs-12'>Autor: Juan Cid y Mulet</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Hugo Sánchez</p>
                  <h5 className='fw-5 fs-12'>Autor: Julián de Reoyo</h5>
                </li>  
                <li>
                  <p className='fw-6 fs-15'>Futbol en Yucatán</p>
                  <h5 className='fw-5 fs-12'>Autor: Carlos Iturralde Rivero</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Futbol Adentro</p>
                  <h5 className='fw-5 fs-12'>Autor: Juan Ignacio Basaguren</h5>
                </li>  
                <li>
                  <p className='fw-6 fs-15'>La verdadera historia de la selección nacional de futbol 1928 - 1986</p>
                  <h5 className='fw-5 fs-12'>Autor: Ricardo Castillo Mireles</h5>
                </li>  
                <li>
                  <p className='fw-6 fs-15'>Eso llamado futbol</p>
                  <h5 className='fw-5 fs-12'>Autor: Germán Malvido</h5>
                </li>  
                <li>
                  <p className='fw-6 fs-15'>Como concursar en pronósticos</p>
                  <h5 className='fw-5 fs-12'>Autor: Simon Sakal</h5>
                </li>  
                <li>
                  <p className='fw-6 fs-15'>Hacia Espana 82…y otros mundiales</p>
                  <h5 className='fw-5 fs-12'>Autor: Agustin Gonzales "Escopeta"</h5>
                </li>  
                <li>
                  <p className='fw-6 fs-15'>Cosas del futbol</p>
                  <h5 className='fw-5 fs-12'>Autor: Iracheta y Merino</h5>
                </li>  
                <li>
                  <p className='fw-6 fs-15'>Los once de la tribu</p>
                  <h5 className='fw-5 fs-12'>Autor: Juan Villoro</h5>
                </li>
              <li>
                  <p className='fw-6 fs-15'>Lenin en el futbol</p>
                  <h5 className='fw-5 fs-12'>Autor: Guillermo Samperio</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Héroes del consumo popular</p>
                  <h5 className='fw-5 fs-12'>Autor: Héctor Huerta</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>La juventud y la Copa del Mundo</p>
                  <h5 className='fw-5 fs-12'>Autor: José Ávila Garibay</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>En un lugar de la Cancha</p>
                  <h5 className='fw-5 fs-12'>Autor: Roberto Gómez Junco</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Historias del Club León-Volumen 1</p>
                  <h5 className='fw-5 fs-12'>Autor: Carlos Márquez Flores</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Mis memorias del equipo León</p>
                  <h5 className='fw-5 fs-12'>Autor: José de Jesús Cruz Martínez</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Enseñanzas del Futbol</p>
                  <h5 className='fw-5 fs-12'>Autor: Tomás Calvillo</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>El Estadio Azteca, historia del coloso de Santa Úrsula</p>
                  <h5 className='fw-5 fs-12'>Autor: </h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Cámara húngara</p>
                  <h5 className='fw-5 fs-12'>Autor: Javier García Galianoz</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Viva El Futbol</p>
                  <h5 className='fw-5 fs-12'>Autor: Gustavo Petterson Hernández</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Las que aman el futbol y las que no tanto</p>
                  <h5 className='fw-5 fs-12'>Autor: Elvira Hernández Carballido</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Estadio Corregidora de Querétaro, 25 años de historia</p>
                  <h5 className='fw-5 fs-12'>Autor: Iván Torres Lechuga</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>La economía del futbol</p>
                  <h5 className='fw-5 fs-12'>Autor: Ciro Murayama</h5>
                  <h5 className='fw-5 fs-12'>Autor: Rolando Jiménez Turegano</h5>
                </li>
                <li>
                  <p className='fw-6 fs-15'>Club de futbol La Piedad Segunda División Premier</p>
                  <h5 className='fw-5 fs-12'>Autor: </h5>
                </li>
              </ul>
            </div>
          </div>
      </div>
    </section>
  )
  case "english":
    return (
      <section className='about'>
        <div className='container'>
            <div className='about-text'>
              <h2 className='about-title fs-26 ls-1'>Mexico's Shelf - And also exists...</h2>
             <p>The next books have been published, although we couldn't get them to upload their info and front picture. If someone wish to add a missing item to this list, please contact us.</p>
              <div className="vinul"> 
                <ul>
                  <li>
                    <p className='fw-6 fs-15'>Historia del Toluca</p>
                    <h5 className='fw-5 fs-12'>Author: Juan Cid y Mulet</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Hugo Sánchez</p>
                    <h5 className='fw-5 fs-12'>Author: Julián de Reoyo</h5>
                  </li>  
                  <li>
                    <p className='fw-6 fs-15'>Futbol en Yucatán</p>
                    <h5 className='fw-5 fs-12'>Author: Carlos Iturralde Rivero</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Futbol Adentro</p>
                    <h5 className='fw-5 fs-12'>Author: Juan Ignacio Basaguren</h5>
                  </li>  
                  <li>
                    <p className='fw-6 fs-15'>La verdadera historia de la selección nacional de futbol 1928 - 1986</p>
                    <h5 className='fw-5 fs-12'>Author: Ricardo Castillo Mireles</h5>
                  </li>  
                  <li>
                    <p className='fw-6 fs-15'>Eso llamado futbol</p>
                    <h5 className='fw-5 fs-12'>Author: Germán Malvido</h5>
                  </li>  
                  <li>
                    <p className='fw-6 fs-15'>Como concursar en pronósticos</p>
                    <h5 className='fw-5 fs-12'>Author: Simon Sakal</h5>
                  </li>  
                  <li>
                    <p className='fw-6 fs-15'>Hacia Espana 82…y otros mundiales</p>
                    <h5 className='fw-5 fs-12'>Author: Agustin Gonzales "Escopeta"</h5>
                  </li>  
                  <li>
                    <p className='fw-6 fs-15'>Cosas del futbol</p>
                    <h5 className='fw-5 fs-12'>Author: Iracheta y Merino</h5>
                  </li>  
                  <li>
                    <p className='fw-6 fs-15'>Los once de la tribu</p>
                    <h5 className='fw-5 fs-12'>Author: Juan Villoro</h5>
                  </li>
                <li>
                    <p className='fw-6 fs-15'>Lenin en el futbol</p>
                    <h5 className='fw-5 fs-12'>Author: Guillermo Samperio</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Héroes del consumo popular</p>
                    <h5 className='fw-5 fs-12'>Author: Héctor Huerta</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>La juventud y la Copa del Mundo</p>
                    <h5 className='fw-5 fs-12'>Author: José Ávila Garibay</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>En un lugar de la Cancha</p>
                    <h5 className='fw-5 fs-12'>Author: Roberto Gómez Junco</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Historias del Club León-Volumen 1</p>
                    <h5 className='fw-5 fs-12'>Author: Carlos Márquez Flores</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Mis memorias del equipo León</p>
                    <h5 className='fw-5 fs-12'>Author: José de Jesús Cruz Martínez</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Enseñanzas del Futbol</p>
                    <h5 className='fw-5 fs-12'>Author: Tomás Calvillo</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>El Estadio Azteca, historia del coloso de Santa Úrsula</p>
                    <h5 className='fw-5 fs-12'>Author: </h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Cámara húngara</p>
                    <h5 className='fw-5 fs-12'>Author: Javier García Galianoz</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Viva El Futbol</p>
                    <h5 className='fw-5 fs-12'>Author: Gustavo Petterson Hernández</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Las que aman el futbol y las que no tanto</p>
                    <h5 className='fw-5 fs-12'>Author: Elvira Hernández Carballido</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Estadio Corregidora de Querétaro, 25 años de historia</p>
                    <h5 className='fw-5 fs-12'>Author: Iván Torres Lechuga</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>La economía del futbol</p>
                    <h5 className='fw-5 fs-12'>Author: Ciro Murayama</h5>
                    <h5 className='fw-5 fs-12'>Author: Rolando Jiménez Turegano</h5>
                  </li>
                  <li>
                    <p className='fw-6 fs-15'>Club de futbol La Piedad Segunda División Premier</p>
                    <h5 className='fw-5 fs-12'>Author: </h5>
                  </li>
                </ul>
              </div>
            </div>
        </div>
      </section>
    )
    default:
      break;
}
}

export default MXTambien