import React, { useContext } from "react";
import { BookContext } from '../../../../context/books';
import EditarLibro from '../editLibs/editLibs';
import './selecEdit.css';


const Buscador = () => {
    const { fetchLibros, fetchGuias, fetchOtros, tablaEdit, setTablaEdit } = useContext(BookContext);

    const handleSubmit = async (e, tabla) => {
        e.preventDefault();
        let fetch;
        switch (tabla) {
            case "Más deportes":
                fetch = fetchLibros("masdep");
                break;
            case "Guías México" :
                fetch = fetchGuias("México", "all");
                break;
            case "Guías Argentina":
                fetch = fetchGuias("Argentina", "all");
                break;
            case "Guías Internacionales":
                fetch = fetchGuias("Internacionales", "masguias");
                break;
            case "Rock":
                fetch = fetchOtros("rock");
                break;
            case "Automovilismo":
                fetch = fetchOtros("automov");
                break;
            case "Y también...":
                fetch = fetchOtros("tambien");
                break;
            default:
                break;
        }
        try {
            await fetch;
            setTablaEdit(tabla);
        } catch (err) {
            console.log(err);
          }
        return <EditarLibro />
    }
    


    return (
        <nav className='buscador' id = "buscador">
            <div className="buscador-subtitle">
                <span>Seleccionador</span>
            </div>
            <div className="buscador-collapse">
                <ul className="buscador-nav">
                    <li className="us-form center">
                        Base de datos:
                            <select
                                name="tabla"
                                type="tabla"
                                placeholder="Seleccionar tabla"
                                value={tablaEdit ? tablaEdit : "Seleccionar tabla"}
                                onChange={(e) => handleSubmit(e, e.target.value)}
                                required
                            >
                                <option disabled>Seleccionar tabla</option>
                                <option>Guías México</option>
                                <option>Guías Argentina</option>
                                <option>Guías Internacionales</option>
                                <option>Automovilismo</option>
                                <option>Más Deportes</option>
                                <option>Rock</option>
                                <option>Y también...</option>
                            </select>
                        
                    </li>
                </ul>
         </div>
        </nav>
    )
}

export default Buscador;