import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes,  Route, Navigate } from 'react-router-dom';
import { AppProvider } from './context/books';
import './index.css';
import Home from './pages/Home/Home';
import Inicio from './pages/Inicio/Inicio';
import Autor from './pages/Autor/Autor';
import Links from './pages/Links/Links';
import Agradec from './pages/Agradec/Agradec';
import Razones from './pages/Razones/Razones';
import MXTambien from './pages/MXTambien/MXTambien';
import Descargas from './pages/Descargas/Descargas';
import Contact from './pages/Contact/Contact';
import BookList from './components/BookList/BookList';
import GuiaList from './components/GuiaList/GuiaList';
import OtrosList from './components/OtrosList/OtrosList';
import RevistasList from './components/RevistasList/RevistasList';
import PresentList from './components/PresentList/PresentList';
import BookDetails from './components/BookDetails/BookDetails';
import GuiaDetails from './components/GuiaDetails/GuiaDetails';
import OtrosDetails from './components/OtrosDetails/OtrosDetails';
import PresentDetails from './components/PresentDetails/PresentDetails';
import VentasDetails from './components/VentasDetails/VentasDetails';
import RevistasDetails from './components/RevistasDetails/RevistasDetails';
import Admin from './pages/Admin/Admin';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
    <BrowserRouter>
      <Routes>
        <Route path = "/" element = {<Home />}>
        <Route index element = {<Inicio />} />
        <Route path = "inicio" element = {<Inicio />} />
        <Route path = "autor" element = {<Autor />} />
        <Route path = "links" element = {<Links />} />
        <Route path = "agradec" element = {<Agradec />} />
        <Route path = "razones" element = {<Razones />} />
        <Route path = "mxytamb" element = {<MXTambien />} />
        <Route path = "descargas" element = {<Descargas />} />
        <Route path = "contact" element = {<Contact />} />
        <Route path = "book" element = {<BookList />} />
        <Route path = "guia" element = {<GuiaList />} />
        <Route path = "otros" element = {<OtrosList />} />
        <Route path = "present" element = {<PresentList />} />
        <Route path = "revista" element = {<RevistasList />} />
        <Route path = "/book/id/:idDet" element = { <BookDetails />} />
        <Route path = "/guia/id/:idDet" element = { <GuiaDetails />} /> 
        <Route path = "/otros/id/:idDet" element = { <OtrosDetails />} />         
        <Route path = "/present/id/:idDet" element = { <PresentDetails />} />
        <Route path = "/venta/id/:idDet" element = { <VentasDetails />} />
        <Route path = "/revista/id/:idDet" element = { <RevistasDetails />} />   
        <Route path = "/controlmva" element = { <Admin />} />
        <Route path="*" element={<Navigate to="/inicio" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </AppProvider>
);

