/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendMailC = /* GraphQL */ `
  mutation SendMailC($input: SendMailInput!) {
    sendMailC(input: $input)
  }
`;

export const copyDBs = /* GraphQL */ `
  mutation CopyDBs($input: CopyDBsInput!) {
    copyDBs(input: $input)
  }
`;

export const createAutomov = /* GraphQL */ `
  mutation CreateAutomov(
    $input: CreateAutomovInput!
    $condition: ModelAutomovConditionInput
  ) {
    createAutomov(input: $input, condition: $condition) {
      id
      titulo
      autor
      diaedicion
      mesedicion
      anoedicion
      portada
      categ
    }
  }
`;

export const updateAutomov = /* GraphQL */ `
  mutation UpdateAutomov(
    $input: UpdateAutomovInput!
    $condition: ModelAutomovConditionInput
  ) {
    updateAutomov(input: $input, condition: $condition) {
      id
      titulo
      autor
      diaedicion
      mesedicion
      anoedicion
      portada
      categ
    }
  }
`;


export const createLibros = /* GraphQL */ `
  mutation CreateLibros(
    $input: CreateLibrosInput!
    $condition: ModelLibrosConditionInput
  ) {
    createLibros(input: $input, condition: $condition) {
      id
      titulo
      pais
      autor
      nacautor
      contautor
      editorial
      diaedicion
      mesedicion
      anoedicion
      tiraje
      paginas
      portada
      comentario
      momento
      diceautor
    }
  }
`;
export const updateLibros = /* GraphQL */ `
  mutation UpdateLibros(
    $input: UpdateLibrosInput!
    $condition: ModelLibrosConditionInput
  ) {
    updateLibros(input: $input, condition: $condition) {
      id
      titulo
      pais
      autor
      nacautor
      contautor
      editorial
      diaedicion
      mesedicion
      anoedicion
      tiraje
      paginas
      portada
      comentario
      momento
      diceautor
    }
  }
`;

export const createGuiasarg = /* GraphQL */ `
  mutation CreateGuiasarg(
    $input: CreateGuiasargInput!
    $condition: ModelGuiasargConditionInput
  ) {
    createGuiasarg(input: $input, condition: $condition) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;
export const updateGuiasarg = /* GraphQL */ `
  mutation UpdateGuiasarg(
    $input: UpdateGuiasargInput!
    $condition: ModelGuiasargConditionInput
  ) {
    updateGuiasarg(input: $input, condition: $condition) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;

export const createGuiasint = /* GraphQL */ `
  mutation CreateGuiasint(
    $input: CreateGuiasintInput!
    $condition: ModelGuiasintConditionInput
  ) {
    createGuiasint(input: $input, condition: $condition) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;
export const updateGuiasint = /* GraphQL */ `
  mutation UpdateGuiasint(
    $input: UpdateGuiasintInput!
    $condition: ModelGuiasintConditionInput
  ) {
    updateGuiasint(input: $input, condition: $condition) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;

export const createGuiasmx = /* GraphQL */ `
  mutation CreateGuiasmx(
    $input: CreateGuiasmxInput!
    $condition: ModelGuiasmxConditionInput
  ) {
    createGuiasmx(input: $input, condition: $condition) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;
export const updateGuiasmx = /* GraphQL */ `
  mutation UpdateGuiasmx(
    $input: UpdateGuiasmxInput!
    $condition: ModelGuiasmxConditionInput
  ) {
    updateGuiasmx(input: $input, condition: $condition) {
      id
      titulo
      institucion
      nomfoto
    }
  }
`;

export const createMasdep = /* GraphQL */ `
  mutation CreateMasdep(
    $input: CreateMasdepInput!
    $condition: ModelMasdepConditionInput
  ) {
    createMasdep(input: $input, condition: $condition) {
      id
      titulo
      pais
      autor
      nacautor
      contautor
      editorial
      diaedicion
      mesedicion
      anoedicion
      tiraje
      paginas
      portada
      comentario
      momento
      diceautor
    }
  }
`;
export const updateMasdep = /* GraphQL */ `
  mutation UpdateMasdep(
    $input: UpdateMasdepInput!
    $condition: ModelMasdepConditionInput
  ) {
    updateMasdep(input: $input, condition: $condition) {
      id
      titulo
      pais
      autor
      nacautor
      contautor
      editorial
      diaedicion
      mesedicion
      anoedicion
      tiraje
      paginas
      portada
      comentario
      momento
      diceautor
    }
  }
`;

export const createRock = /* GraphQL */ `
  mutation CreateRock(
    $input: CreateRockInput!
    $condition: ModelRockConditionInput
  ) {
    createRock(input: $input, condition: $condition) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada
    }
  }
`;
export const updateRock = /* GraphQL */ `
  mutation UpdateRock(
    $input: UpdateRockInput!
    $condition: ModelRockConditionInput
  ) {
    updateRock(input: $input, condition: $condition) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada

    }
  }
`;

export const createTambien = /* GraphQL */ `
  mutation CreateTambien(
    $input: CreateTambienInput!
    $condition: ModelTambienConditionInput
  ) {
    createTambien(input: $input, condition: $condition) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada
    }
  }
`;
export const updateTambien = /* GraphQL */ `
  mutation UpdateTambien(
    $input: UpdateTambienInput!
    $condition: ModelTambienConditionInput
  ) {
    updateTambien(input: $input, condition: $condition) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada
    }
  }
`;

export const createCarruselItems = /* GraphQL */ `
  mutation CreateCarruselItems(
    $input: CreateCarruselItemsInput!
    $condition: ModelCarruselItemsConditionInput
  ) {
    createCarruselItems(input: $input, condition: $condition) {
      id
      indice
      titulo
      nomfoto
    }
  }
`;
export const updateCarruselItems = /* GraphQL */ `
  mutation UpdateCarruselItems(
    $input: UpdateCarruselItemsInput!
    $condition: ModelCarruselItemsConditionInput
  ) {
    updateCarruselItems(input: $input, condition: $condition) {
      id
      indice
      titulo
      nomfoto
    }
  }
`;
export const createRevistas = /* GraphQL */ `
  mutation CreateRevistas(
    $input: CreateRevistasInput!
    $condition: ModelRevistasConditionInput
  ) {
    createRevistas(input: $input, condition: $condition) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada
    }
  }
`;

export const updateRevistas = /* GraphQL */ `
  mutation UpdateRevistas(
    $input: UpdateRevistasInput!
    $condition: ModelRevistasConditionInput
  ) {
    updateRevistas(input: $input, condition: $condition) {
      id
      titulo
      autor
      editorial
      diaedicion
      mesedicion
      anoedicion
      portada
    }
  }
`;
